export interface SubscriptionModel {
    /**
     * Unique record id.
     */
    id: string;
    /**
     * Mail of subscribed user.
     */
    email: string;
    /**
     * Subscription date.
     * ISO String date format.
     */
    subscriptionDate: string;
}
