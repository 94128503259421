export namespace ToolbarStepsActions {
    export class AddStep {
        static readonly type = '[Editor] Add step';
    }

    export class AddStepAtIndex {
        static readonly type = '[Editor] Add step at index';

        constructor(public index: number) {
        }
    }

    export class SelectStep {
        static readonly type = '[Editor] Select step';

        constructor(public index: number) {
        }
    }

    export class DuplicateSelectedStep {
        static readonly type = '[Editor] Duplicate selected step';
    }

    export class MoveStepToFirstPosition {
        static readonly type = '[Editor] Move step to first position';
    }

    export class MoveStepToLastPosition {
        static readonly type = '[Editor] Move step to last position';
    }

    export class ResetSelectedStep {
        static readonly type = '[Editor] Reset selected step';
    }

    export class RemoveSelectedStep {
        static readonly type = '[Editor] Remove selected step';
    }

    export class RemoveAllSteps {
        static readonly type = '[Editor] Remove all steps';
    }

    export class SelectStepEvent {
        static readonly type = '[Editor] Select step EVENT';

        constructor(public selectedStepIndex: number, public selectedStepId: string | undefined) {
        }
    }
}
