import {PricingPromotionType} from './pricing-promotion-type.enum';
import {PromotionPostActionType} from './promotion-post-action-type.enum';

export interface PricingPromotionModel {
    /**
     * The type of promotion.
     */
    type: PricingPromotionType;
    /**
     * A few words about promotion.
     */
    description: string;
    /**
     * If promotion is a percentage discount, then here is the value.
     * Note that 0.05 here means 5% etc.
     */
    percentageValue?: number;
    /**
     * A numerical value of discount.
     */
    numberValue?: number;
    /**
     * An additional operation after promotions calculation to be done
     * for final price.
     */
    postAction?: PromotionPostActionType;
    /**
     * Moment of start of the promotion.
     * ISO String dat format.
     */
    startDate: string;
    /**
     * Moment of promotion expiration, if empty, then the promotion lasts until further notice.
     * ISO String dat format.
     */
    endDate?: string;
}
