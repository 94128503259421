<div class="modal-header">
    <h4 class="modal-title">Ustawienia obrazu</h4>
    <button type="button" class="close-button" aria-label="Zamknij" (click)="activeModal.dismiss(true)"></button>
</div>
<div class="modal-body text-gray-700">
    <div class="row">
        <div class="col-8">
            <ip-object-value-setting [value]="editorObject.id" [isLink]="false"
                                     label="Identyfikator"></ip-object-value-setting>
        </div>
        <div class="col-4 text-end">
            <ip-object-color-setting [selectedColor]="color" label="Kolor obiektu"
                                     (selectedColorChange)="onObjectColorChange($event)"></ip-object-color-setting>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <ip-object-selector-setting label="Obiekt nadrzędny"
                                        [childObjectId]="editorObject.id"
                                        [selectedObjectId]="editorObject.parentId"
                                        (selectedObjectChange)="onParentObjectChanged($event)">
            </ip-object-selector-setting>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <ip-object-value-setting [value]="imageUrl" label="Link obrazu"
                                     [canCopyToClipboard]="false"></ip-object-value-setting>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <ip-object-value-setting [value]="imageType" label="Typ obrazu" [canCopyToClipboard]="false"
                                     [isLink]="false"></ip-object-value-setting>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-lg-8 col-xxl-7">
            <ip-object-input-setting label="Nazwa" [value]="editorObject.name"
                                     (valueChange)="onObjectNameChange($event)"></ip-object-input-setting>
        </div>
        <div class="col-12 col-lg-4 col-xxl-5 align-bottom text-end">
            <button class="change-picture-btn ip-btn-framed ip-btn-secondary disabled" (click)="onChangePicture()">Zmień
                obraz
            </button>
        </div>
    </div>


</div>
<!--<div class="modal-footer">-->
<!--    <button type="button" class="cancel-btn" (click)="activeModal.close('Cancel click')">Anuluj</button>-->
<!--    <button type="button" (click)="activeModal.close('OK click')">OK</button>-->
<!--</div>-->
