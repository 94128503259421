import {FileModel} from 'common-lib';

export namespace FileUploaderActions {
    export class Show {
        static readonly type = '[Uploader] Show uploader';
    }

    export class Save {
        static readonly type = '[Uploader] Save image';

        constructor(public file: FileModel) {
        }
    }

    export class Cancel {
        static readonly type = '[Uploader] Cancel image save';
    }
}
