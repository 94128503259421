import {ErrorResponse} from './error.response';

export interface GeneralResponse extends ErrorResponse {
    /**
     * Response message.
     */
    msg: string;
    /**
     * A newly created record ID returned by creation record request.
     */
    createdId?: string;
}
