import {OperatingSystem} from 'common-lib';
import {UITraining} from '../../models/ui-training.model';
import {EditorFlow} from './editor-flow.enum';
import {EditorMode} from './editor-mode.enum';
import {FlowSettingsModel} from './flow-settings.model';
import {HintBoxSettingsModel} from './hint-box-settings.model';

/**
 * Main editor state - "editor".
 */
export interface EditorStateModel {
    training: UITraining;
    /**
     * Currently selected training step index.
     */
    selectedStepIndex: number;
    /**
     * Currently selected step ID.
     */
    selectedStepId: string | undefined;
    /**
     * Currently selected object in editor.
     */
    selectedEditorObjectId: string | undefined;
    /**
     * Visual mode of editor. Generally its visual state is managed by one component - {@see EditorHostComponent}.
     */
    mode: EditorMode;
    /**
     * Defines current editor flow settings.
     */
    flowSettings: FlowSettingsModel;
    /**
     * Settings describing hint box data & visibility.
     */
    hintBoxSettings: HintBoxSettingsModel;
}

export const defaultEditorState = <EditorStateModel> {
    training: {
        id: 'NEW-TRAINING',
        name: '',
        createdDate: new Date(),
        description: '',
        difficultLevel: 1,
        os: OperatingSystem.Windows,
        osVersion: '10',
        isArchived: false,
        pricing: {
            basePrice: 0,
            price: 0,
            hasPromotion: false,
            currency: 'PLN'
        },
        steps: []
    },
    selectedStepIndex: -1,
    selectedStepId: undefined,
    selectedEditorObjectId: undefined,
    mode: EditorMode.Default,
    flowSettings: {
        flow: EditorFlow.None,
        selectedEditorObjectId: undefined,
        selectedTrainingStepId: undefined,
        currentFlowStep: 0,
        uploadedFile: undefined,
        sourceTriggerObjectId: undefined,
        sourceTriggerStepId: undefined
    },
    hintBoxSettings: {
        isExpanded: false,
        isVisible: false,
        message: 'W tym trybie wszystkie obiekty interaktywne zostały tymczasowo wyłaczone ale są wciąż widoczne.',
        title: 'Wybierz obraz klikając w niego'
    }
};
