import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {UITraining} from '../models/ui-training.model';
import {exampleTraining} from '../store/models/editor-example-training-store.data';
import {TrainingMapperService} from './training-mapper.service';

@Injectable({
    providedIn: 'root'
})
export class TrainingLoaderService {

    constructor(private readonly mapper: TrainingMapperService) {
    }

    /**
     * Loads a training from API and returns it here as a UI model.
     * @param id ID of the training to load.
     */
    public loadTraining(id: string): Observable<UITraining> {
        // Temporary load example model, instead of real one
        return of(this.mapper.mapToUI(exampleTraining));
    }
}
