import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Actions, ofActionDispatched, Store} from '@ngxs/store';
import * as _ from 'lodash';
import {Subscription} from 'rxjs';
import {HtmlHelperService} from '../../../services/html-helper.service';
import {ModalsService} from '../../../services/modals.service';
import {EditorActions} from '../../../store/editor.actions';

/**
 * Component used for objects selection, parent or target objects.
 */
@Component({
    selector: 'ip-object-selector-setting',
    templateUrl: './object-selector-setting.component.html',
    styleUrls: ['./object-selector-setting.component.scss']
})
export class ObjectSelectorSettingComponent implements OnInit {
    /**
     * Rela value for visual data.
     * @private
     */
    private currentSelectedObjectId: string | undefined;

    /**
     * A selected object.
     */
    @Input()
    public selectedObjectId: string | undefined;

    /**
     * Currently selected child object for given parent.
     * The id of the object which settings are opened.
     */
    @Input()
    public childObjectId!: string;

    /**
     * A label displayed on the left side of the link.
     */
    @Input()
    public label: string | undefined;

    @Output()
    public selectedObjectChange: EventEmitter<string> = new EventEmitter<string>();

    public get selected(): string {
        if (_.isNil(this.currentSelectedObjectId)) {
            return 'Pulpit'; // TODO: to be localized later
        }
        return this.currentSelectedObjectId;
    }

    constructor(private store: Store,
                private actions$: Actions,
                private modals: ModalsService) {
    }

    public ngOnInit(): void {
        if (_.isNil(this.childObjectId)) {
            throw new Error('Missing childObjectId for ObjectSelectorSettingComponent.');
        }
        this.currentSelectedObjectId = this.selectedObjectId;
    }

    public invokeObjectSelection(): void {
        const subscription$ = this.actions$
                                  .pipe(
                                      ofActionDispatched(EditorActions.SelectParentObjectEvent)
                                  )
                                  .subscribe((action: EditorActions.SelectParentObjectEvent) => {
                                      // undefined value denote cancellation of selection
                                      if (!_.isUndefined(action.parentObjectId)) {
                                          this.currentSelectedObjectId = action.parentObjectId ?? undefined;
                                          this.selectedObjectChange.emit(this.currentSelectedObjectId);
                                      }
                                      subscription$.unsubscribe();
                                      this.modals.revealModal();
                                      this.store.dispatch(new EditorActions.EnableObject(this.childObjectId));
                                  });

        // disable currently selected object
        this.store.dispatch(new EditorActions.DisableObject(this.childObjectId));
        // enter parent object selection mode / flow
        this.store.dispatch(new EditorActions.SelectParentObject(this.childObjectId));
        this.modals.hideModal();
    }
}
