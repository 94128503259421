import {ImageObjectModel} from './image-object.model';
import {InteractiveObjectModel} from './interactive-object.model';

export interface TrainingStepModel {
    id: string;
    /**
     * A set of interactive objects available in this step.
     * This mustn't be empty. There must be at least one interactive element.
     * Objects must be rendered over all other non-interactive objects, starting from index 0,
     * and ending at last one. So from bottom to top of view.
     */
    interactiveObjects?: InteractiveObjectModel[];
    /**
     * A set of all static images placed on screen. Rendering starts from index 0,
     * and ends at last one. So from bottom to top of view.
     */
    imageObjects?: ImageObjectModel[];
}
