import {AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {Actions, ofActionCompleted, Select, Store} from '@ngxs/store';
import {FileModel} from 'common-lib';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {UiEditorObjectType} from '../../models/ui-editor-object-type.enum';
import {UIEditorObject} from '../../models/ui-editor-object.model';
import {DraggingService} from '../../services/dragging.service';
import {HandlesService} from '../../services/handles.service';
import {EditorActions} from '../../store/editor.actions';
import {EditorSelectors} from '../../store/editor.selectors';
import {FileUploaderActions} from '../../store/file-uploader.actions';
import {EditorMode} from '../../store/models/editor-mode.enum';
import {EditorStateModel} from '../../store/models/editor-state.model';
import {HintBoxSettingsModel} from '../../store/models/hint-box-settings.model';
import {ToolbarStepsActions} from '../../store/toolbar-steps.actions';

@Component({
    selector: 'ip-editor-host',
    templateUrl: './editor-host.component.html',
    styleUrls: ['./editor-host.component.scss']
})
export class EditorHostComponent implements OnInit, AfterViewInit, OnDestroy {

    private unsubscribe$ = new Subject();
    public editorMode$!: Observable<EditorMode>;
    @Select(EditorSelectors.getHintBoxSettings) public hintBoxSettings$!: Observable<HintBoxSettingsModel>;
    @ViewChildren('editorObject') editorObjects!: QueryList<any>;
    public objects$: Subject<UIEditorObject[]> = new Subject<UIEditorObject[]>();
    public EditorMode = EditorMode;
    public UIEditorObjectType = UiEditorObjectType;
    public isToolbarExpanded: boolean = true;
    public isEditorToolbarDisabled: boolean = false;
    public isDesktopToolbarDisabled: boolean = false;

    constructor(
        private store: Store,
        private actions: Actions,
        private dragging: DraggingService,
        private handles: HandlesService
    ) {

    }

    public ngOnInit(): void {
        this.editorMode$ = this.store.select(state => (state.editor as EditorStateModel).mode);
        this.editorMode$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(mode => {
                if (mode === EditorMode.ParentObjectSelection) {
                    this.isEditorToolbarDisabled = true;
                    this.isDesktopToolbarDisabled = true;
                }
                else {
                    this.isEditorToolbarDisabled = false;
                    this.isDesktopToolbarDisabled = false;
                }
            });

        // handle objects changes when training was loaded or when step was selected
        // another moments to handle will be:
        // TODO: Handle other moments when objects are changing, like adding new objects, removing, hierarchy changes.
        this.actions
            .pipe(
                takeUntil(this.unsubscribe$),
                ofActionCompleted(
                    EditorActions.LoadTrainingEvent,
                    ToolbarStepsActions.SelectStepEvent,
                    EditorActions.CreateEditorObject,
                    EditorActions.ChangeObjectParentEvent
                )
            )
            .subscribe(() => {
                const state = this.store.snapshot().editor as EditorStateModel;
                if (state.selectedStepIndex === -1 || !state.training.steps || state.training.steps.length === 0) {
                    this.objects$.next([]);
                    this.store.dispatch(new EditorActions.EditorObjectsRendered());
                }
                else {
                    const objects = state.training.steps[state.selectedStepIndex].objects || [];
                    this.objects$.next(objects);
                }
            });
    }

    public ngAfterViewInit(): void {
        // load example training
        // TODO: To be removed and replaced by logic loading training from URL
        setTimeout(
            () => this.store.dispatch(new EditorActions.LoadTraining('')),
            0
        );

        this.editorObjects.changes.subscribe(object => {
            this.store.dispatch(new EditorActions.EditorObjectsRendered());
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.dragging.destroy();
        this.handles.destroy();
    }

    public onUploaderSave(file: FileModel): void {
        this.store.dispatch(new FileUploaderActions.Save(file));
    }

    public onUploaderCancel(): void {
        this.store.dispatch(new FileUploaderActions.Cancel());
    }

    public toolbarExpandedChanged(isExpanded: boolean): void {
        this.isToolbarExpanded = isExpanded;
    }
}
