import {MediaMimeType} from './media-mime-type.enum';

export interface FileSimpleModel {
    /**
     * File unique id.
     */
    id: string;
    /**
     * File name with extension, e.g. "new-file.png".
     */
    fileName: string;
    /**
     * Restricted MIME type for image. Only small number of image formats are available.
     */
    mimeType: MediaMimeType;
    /**
     * The date of image creation.
     * ISO String date format.
     */
    createdDate: string;
}




