import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import * as uuid from 'uuid';

@Component({
    selector: 'ip-object-input-setting',
    templateUrl: './object-input-setting.component.html',
    styleUrls: ['./object-input-setting.component.scss']
})
export class ObjectInputSettingComponent implements OnInit {

    private inputValueSubject$ = new Subject<string>();

    /**
     * A value for the input.
     */
    @Input()
    public value: string | undefined;

    /**
     * A label displayed for the input field.
     */
    @Input()
    public label: string | undefined;

    /**
     * Executed when the input value has changed.
     */
    @Output()
    public valueChange: EventEmitter<string> = new EventEmitter<string>();

    /**
     * Unique id of the field.
     */
    public id: string = uuid.v4();

    constructor() {
    }

    public ngOnInit(): void {
        this.value = this.value ?? '';
        this.inputValueSubject$
            .pipe(
                debounceTime(120)
            )
            .subscribe((newInputValue: string) => {
                this.valueChange.emit(newInputValue);
                console.log(newInputValue);
            });
    }

    public onValueChange(event: KeyboardEvent): void {
        this.inputValueSubject$.next((<any> event.target).value as string);
    }

}
