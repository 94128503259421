import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild
} from '@angular/core';
import {Actions, ofActionDispatched, Store} from '@ngxs/store';
import {ObjectColor} from 'common-lib';
import {asyncScheduler, Subject} from 'rxjs';
import {takeUntil, throttleTime} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {DEFAULT_OBJECT_COLOR} from '../../../constants';
import {HandlePosition} from '../../../models/handle-position.enum';
import {UiEditorObjectType} from '../../../models/ui-editor-object-type.enum';
import {UIEditorObject} from '../../../models/ui-editor-object.model';
import {HtmlHelperService} from '../../../services/html-helper.service';
import {ModalsService} from '../../../services/modals.service';
import {EditorActions} from '../../../store/editor.actions';
import {EditorStateModel} from '../../../store/models/editor-state.model';

@Component({
    selector: 'ip-image-object',
    templateUrl: './image-object.component.html',
    styleUrls: ['./image-object.component.scss']
})
export class ImageObjectComponent implements OnInit, OnDestroy, AfterViewInit {

    private onClick$ = new Subject<MouseEvent>();
    private unsubscribe$ = new Subject();
    private initializedHandles = 0;

    @ViewChild('imageObject', {static: true}) public imageObjectRef!: ElementRef;
    @Input() public objectModel!: UIEditorObject;
    public children: Subject<UIEditorObject[]> = new Subject<UIEditorObject[]>();
    public isSelected: boolean = false;
    public isEnabled: boolean = true;
    public HandlePosition = HandlePosition;

    public get color(): ObjectColor {
        return this.objectModel.color || DEFAULT_OBJECT_COLOR;
    }

    public get image(): string {
        return `url('${environment.filesUrl}/${this.objectModel.imageId}')`;
    }

    constructor(
        private renderer: Renderer2,
        private ngZone: NgZone,
        private store: Store,
        private modals: ModalsService,
        private html: HtmlHelperService,
        private actions: Actions
    ) {
        // handle deselection
        this.actions
            .pipe(
                takeUntil(this.unsubscribe$),
                ofActionDispatched(EditorActions.DeselectObjectsEvent)
            )
            .subscribe(() => {
                this.isSelected = false;
            });

        // handle selection
        this.actions
            .pipe(
                takeUntil(this.unsubscribe$),
                ofActionDispatched(EditorActions.SelectObjectEvent)
            )
            .subscribe((ctx: EditorActions.SelectObjectEvent) => {
                if (this.objectModel.id === ctx.object.id) {
                    this.initializedHandles = 0;
                    this.isSelected = true;

                }
            });

        // handle disable
        this.actions
            .pipe(
                takeUntil(this.unsubscribe$),
                ofActionDispatched(EditorActions.DisableObject)
            )
            .subscribe((ctx: EditorActions.DisableObject) => {
                if (this.objectModel.id === ctx.id) {
                    this.isEnabled = false;
                }
            });

        // handle enable
        this.actions
            .pipe(
                takeUntil(this.unsubscribe$),
                ofActionDispatched(EditorActions.EnableObject)
            )
            .subscribe((ctx: EditorActions.EnableObject) => {
                if (this.objectModel.id === ctx.id) {
                    this.isEnabled = true;
                }
            });

        // handle object data changes
        this.actions
            .pipe(
                takeUntil(this.unsubscribe$),
                ofActionDispatched(EditorActions.ChangeObjectDataEvent)
            )
            .subscribe((ctx: EditorActions.ChangeObjectDataEvent) => {
                if (this.objectModel.id === ctx.object.id) {
                    this.objectModel = ctx.object;
                    this.html.updateVisualObject(this.objectModel);
                }
            });
    }

    public ngOnInit(): void {

    }

    public ngAfterViewInit(): void {
        if (!this.objectModel) {
            throw new Error('Missing image object model for ImageObjectComponent.');
        }
        if (this.objectModel.type != UiEditorObjectType.Image) {
            throw new Error(`The editor object type for ImageObjectComponent must be "${UiEditorObjectType.Image}".`);
        }

        this.onClick$
            .pipe(
                takeUntil(this.unsubscribe$),
                throttleTime(100, asyncScheduler, {trailing: true})
            )
            .subscribe(() => {
                if (!this.isSelected) {
                    this.store.dispatch(new EditorActions.SelectObject(this.objectModel.id));
                }
            });

        this.html.updateVisualObject(this.objectModel);
        this.refreshChildren();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public onClick(event: MouseEvent): void {
        event.stopPropagation();
        this.onClick$.next(event);
    }

    public onHandleInitialized(): void {
        this.initializedHandles++;
        if (this.initializedHandles === 4) {
            setTimeout(
                () => this.store.dispatch(new EditorActions.SelectObjectCompleteEvent({...this.objectModel})),
                0
            );
        }
    }

    private refreshChildren(): void {
        const state = this.store.snapshot().editor as EditorStateModel;
        if (state.selectedStepIndex === -1 || this.objectModel.children?.length === 0) {
            return;
        }

        const step = state.training.steps![state.selectedStepIndex];
        const childObjects: UIEditorObject[] = [];
        this.objectModel.children?.forEach((childId: string) => {
            const childIndex = step.objectsById[childId];
            childObjects.push(step.objects[childIndex]);
        });

        setTimeout(() => this.children.next(childObjects), 0);
    }
}
