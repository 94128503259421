<div class="windows-desktop d-flex flex-column h-100 position-relative" [class.disabled-toolbar]="disabledToolbar">
    <div #workspace id="EDITOR_DESKTOP" class="workspace flex-grow-1 d-flex flex-column position-relative"
         (click)="onWorkspaceClick()">
        <div class="step-info">Selected step: {{(selectedStep$ | async)?.id}}</div>
        <ng-content></ng-content>
    </div>
    <div class="windows-desktop-taskbar d-flex flex-row align-items-center">
        <img
            src="assets/images/win-menu-icon.png"
            class="windows-desktop-taskbar-icon"
        />
        <img
            src="assets/images/win-tasks-icon.png"
            class="windows-desktop-taskbar-icon"
        />
        <img
            src="assets/images/win-explorer-icon.png"
            class="windows-desktop-taskbar-icon"
        />
        <img
            src="assets/images/ms-store-icon.png"
            class="windows-desktop-taskbar-icon"
        />
        <div class="flex-grow-1"></div>
        <ip-windows-clock class="h-100 ps-2 pe-2"></ip-windows-clock>
        <div class="ms-2"></div>
    </div>
    <ng-content select="[overlay]"></ng-content>
</div>
