import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import * as _ from 'lodash';
import {UIEditorObject} from '../models/ui-editor-object.model';
import {EditorStateModel} from '../store/models/editor-state.model';

@Injectable({
    providedIn: 'root'
})
export class StoreHelperService {
    private get state(): EditorStateModel {
        return this.store.snapshot().editor as EditorStateModel;
    }

    constructor(private store: Store) {
    }

    public getEditorObjectById(id: string): UIEditorObject | undefined {
        const state = this.state;
        const objectIndex = this.getObjectIndexById(id);
        if (_.isNil(objectIndex)) {
            return undefined;
        }
        const step = state.training.steps[state.selectedStepIndex];
        return _.cloneDeep(step.objects[objectIndex]);
    }

    public getObjectIndexById(objectId: string): number | undefined {
        const state = this.state;
        if (!state.selectedStepId) {
            return undefined;
        }
        const step = state.training.steps[state.selectedStepIndex];
        return step.objectsById[objectId];
    }

    public getSelectedObject(): UIEditorObject | undefined {
        const state = this.store.snapshot().editor as EditorStateModel;
        if (!state.selectedEditorObjectId) {
            return undefined;
        }
        return this.getEditorObjectById(state.selectedEditorObjectId);
    }
}
