import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Action, Actions, ofActionCompleted, State, StateContext} from '@ngxs/store';
import {append, patch, updateItem} from '@ngxs/store/operators';
import {AuthService, PlacementOrigin} from 'common-lib';
import * as _ from 'lodash';
import {Observable, of} from 'rxjs';
import {switchMap, take, tap} from 'rxjs/operators';
import * as uuid from 'uuid';
import {UIEditorObjectData} from '../models/ui-editor-object-data.model';
import {UIEditorObject} from '../models/ui-editor-object.model';
import {UITrainingStep} from '../models/ui-training-step.model';
import {UITraining} from '../models/ui-training.model';
import {HtmlHelperService} from '../services/html-helper.service';
import {StoreHelperService} from '../services/store-helper.service';
import {TrainingLoaderService} from '../services/training-loader.service';
import {EditorActions} from './editor.actions';
import {FileUploaderActions} from './file-uploader.actions';
import {HintBoxActions} from './hint-box.actions';
import {EditorFlow} from './models/editor-flow.enum';
import {EditorMode} from './models/editor-mode.enum';
import {defaultEditorState, EditorStateModel} from './models/editor-state.model';
import {ToolbarMenuActions} from './toolbar-menu.actions';
import {ToolbarStepsActions} from './toolbar-steps.actions';

@State<EditorStateModel>({name: 'editor', defaults: defaultEditorState})
@Injectable()
export class EditorReducers {

    constructor(
        private router: Router,
        private auth: AuthService,
        private loader: TrainingLoaderService,
        private actions: Actions,
        private helper: StoreHelperService,
        private html: HtmlHelperService
    ) {
    }

    @Action(EditorActions.EditorObjectsRendered)
    editorObjectsRendered(ctx: StateContext<EditorStateModel>): void {
        this.log(`[ACTION] EditorActions.EditorObjectsRendered`);
    }

    // ----------------------------------------------------------------------- Uploader actions
    @Action(FileUploaderActions.Show)
    uploaderShow(ctx: StateContext<EditorStateModel>): void {
        this.log(`[ACTION] FileUploaderActions.Show`);
        ctx.patchState({
            mode: EditorMode.FileUpload
        });
    }

    @Action(FileUploaderActions.Save)
    uploaderSave(ctx: StateContext<EditorStateModel>, action: FileUploaderActions.Save): void {
        this.log(`[ACTION] FileUploaderActions.Save`);
        const state = ctx.getState();
        ctx.patchState({
            mode: EditorMode.Default,
            flowSettings: {
                ...state.flowSettings,
                uploadedFile: action.file
            }
        });
    }

    @Action(FileUploaderActions.Cancel)
    uploaderCancel(ctx: StateContext<EditorStateModel>): void {
        this.log(`[ACTION] FileUploaderActions.Cancel`);
        const state = ctx.getState();
        ctx.patchState({
            mode: EditorMode.Default,
            flowSettings: {
                ...state.flowSettings,
                uploadedFile: undefined
            }
        });
    }

    // ----------------------------------------------------------------------- HintBox actions
    @Action(HintBoxActions.Collapsed)
    hintBoxCollapsed(ctx: StateContext<EditorStateModel>): void {
        this.log(`[ACTION] HintBoxActions.Collapsed`);
    }

    @Action(HintBoxActions.Expanded)
    hintBoxExpanded(ctx: StateContext<EditorStateModel>): void {
        this.log(`[ACTION] HintBoxActions.Expanded`);
    }

    @Action(HintBoxActions.Cancel)
    hintBoxCancel(ctx: StateContext<EditorStateModel>): Observable<void> {
        this.log(`[ACTION] HintBoxActions.Cancel`);
        const state = ctx.getState();
        if (state.mode == EditorMode.ParentObjectSelection) {
            return ctx.dispatch(new EditorActions.SelectParentObjectEvent(undefined));
        }

        return of();
    }

    // ----------------------------------------------------------------------- Desktop actions
    @Action(EditorActions.DesktopClick)
    desktopClick(ctx: StateContext<EditorStateModel>): void {
        this.log(`[ACTION] EditorActions.DesktopClick`);
        const state = ctx.getState();

        if (!!state.selectedEditorObjectId) {
            ctx.dispatch(new EditorActions.DeselectObjects()); // deselect all objects
        }

        if (state.mode == EditorMode.ParentObjectSelection) {
            ctx.dispatch(new EditorActions.SelectParentObjectEvent(null)); // null means desktop
        }
    }

    // ----------------------------------------------------------------------- Toolbar steps menu actions
    @Action(ToolbarStepsActions.AddStep)
    addStep(ctx: StateContext<EditorStateModel>): Observable<void> {
        this.log(`[ACTION] AddStep`);
        if (ctx.getState().mode !== EditorMode.Default) {
            return of();
        }
        return this.wrapStepChanges(ctx, () => {
            const state = ctx.getState();
            const newStep = <UITrainingStep> {
                id: uuid.v4(),
                objects: [],
                objectsById: {}
            };

            ctx.patchState({
                training: {
                    ...state.training,
                    steps: [
                        ...state.training.steps,
                        newStep
                    ]
                },
                selectedStepIndex: state.training.steps.length,
                selectedStepId: newStep.id
            });
        });
    }

    @Action(ToolbarStepsActions.AddStepAtIndex)
    addStepAtIndex(ctx: StateContext<EditorStateModel>, action: ToolbarStepsActions.AddStepAtIndex): Observable<void> {
        this.log(`[ACTION] AddStepAtIndex (idx=${action.index})`);
        return this.wrapStepChanges(ctx, () => {
            const state = ctx.getState();
            const newStep = <UITrainingStep> {
                id: uuid.v4(),
                objects: [],
                objectsById: {}
            };
            let newSteps = [...state.training.steps];
            newSteps.splice(action.index, 0, newStep);
            ctx.patchState({
                training: {
                    ...state.training,
                    steps: newSteps
                },
                selectedStepIndex: action.index,
                selectedStepId: newStep.id
            });
        });
    }

    @Action(ToolbarStepsActions.SelectStep)
    selectStep(ctx: StateContext<EditorStateModel>, action: ToolbarStepsActions.SelectStep): Observable<void> {
        this.log(`[ACTION] SelectStep`);
        if (action.index != ctx.getState().selectedStepIndex) {
            return this.wrapStepChanges(ctx, () => {
                const state = ctx.getState();
                const steps = state.training.steps;
                ctx.patchState({
                    selectedStepIndex: action.index,
                    selectedStepId: steps[action.index]?.id
                });
            });
        }
        return of();
    }

    @Action(ToolbarStepsActions.MoveStepToFirstPosition)
    moveStepToFirstPosition(ctx: StateContext<EditorStateModel>): Observable<void> {
        this.log(`[ACTION] MoveStepToFirstPosition`);
        if (ctx.getState().selectedStepIndex === -1) {
            return of();
        }
        return this.wrapStepChanges(ctx, () => {
            const {selectedStepIndex, training} = ctx.getState();
            const steps = [...ctx.getState().training.steps];
            const selectedStep = steps[selectedStepIndex];
            steps.splice(selectedStepIndex, 1);
            ctx.patchState({
                training: {
                    ...training,
                    steps: [
                        selectedStep,
                        ...steps
                    ]
                },
                selectedStepIndex: 0,
                selectedStepId: selectedStep.id
            });
        });
    }

    @Action(ToolbarStepsActions.MoveStepToLastPosition)
    moveStepToLastPosition(ctx: StateContext<EditorStateModel>): Observable<void> {
        this.log(`[ACTION] MoveStepToLastPosition`);
        if (ctx.getState().selectedStepIndex === -1) {
            return of();
        }
        return this.wrapStepChanges(ctx, () => {
            const {selectedStepIndex, training} = ctx.getState();
            const steps = [...ctx.getState().training.steps];
            const selectedStep = steps[selectedStepIndex];
            steps.splice(selectedStepIndex, 1);
            ctx.patchState({
                training: {
                    ...training,
                    steps: [
                        ...steps,
                        selectedStep
                    ]
                },
                selectedStepIndex: steps.length,
                selectedStepId: selectedStep.id
            });
        });
    }

    @Action(ToolbarStepsActions.DuplicateSelectedStep)
    duplicateSelectedStep(ctx: StateContext<EditorStateModel>): Observable<void> {
        this.log(`[ACTION] DuplicateSelectedStep`);
        if (ctx.getState().selectedStepIndex === -1) {
            return of();
        }
        return this.wrapStepChanges(ctx, () => {
            const {selectedStepIndex, training} = ctx.getState();
            const steps = [...ctx.getState().training.steps];
            const selectedStep = _.cloneDeep(steps[selectedStepIndex]);
            const newId = uuid.v4();
            const duplicatedStep = <UITrainingStep> {
                ...selectedStep,
                id: newId
            };
            steps.splice(selectedStepIndex, 0, duplicatedStep);
            ctx.patchState({
                training: {
                    ...training,
                    steps: steps
                }
            });
        });
    }

    @Action(ToolbarStepsActions.ResetSelectedStep)
    resetSelectedStep(ctx: StateContext<EditorStateModel>): Observable<void> {
        this.log(`[ACTION] ResetSelectedStep`);
        if (ctx.getState().selectedStepIndex === -1) {
            return of();
        }
        return this.wrapStepChanges(ctx, () => {
            const {selectedStepIndex, training} = ctx.getState();
            const steps = [...ctx.getState().training.steps];
            steps[selectedStepIndex] = {
                ...steps[selectedStepIndex],
                objects: [],
                objectsById: {}
            };

            ctx.patchState({
                training: {
                    ...training,
                    steps: steps
                }
            });
        });
    }

    @Action(ToolbarStepsActions.RemoveSelectedStep)
    removeSelectedStep(ctx: StateContext<EditorStateModel>): Observable<void> {
        this.log(`[ACTION] RemoveSelectedStep`);
        if (ctx.getState().selectedStepIndex === undefined) {
            return of();
        }
        return this.wrapStepChanges(ctx, () => {
            const state = ctx.getState();
            ctx.patchState({
                training: {
                    ...state.training,
                    steps: state.training.steps.filter((step, index) => index !== state.selectedStepIndex)
                }
            });
            if (state.training.steps.length > 0) {
                const steps = state.training.steps;
                ctx.patchState({
                    selectedStepIndex: state.selectedStepIndex - 1,
                    selectedStepId: state.selectedStepIndex - 1 >= 0 ? steps[state.selectedStepIndex - 1].id : undefined
                });
            }
        });
    }

    @Action(ToolbarStepsActions.RemoveAllSteps)
    removeAllSteps(ctx: StateContext<EditorStateModel>): Observable<void> {
        this.log(`[ACTION] RemoveAllSteps`);
        return this.wrapStepChanges(ctx, () => {
            let state = ctx.getState();
            ctx.patchState({
                training: {
                    ...state.training,
                    steps: []
                },
                selectedStepIndex: -1,
                selectedStepId: undefined
            });
        });
    }

    @Action(ToolbarStepsActions.SelectStepEvent)
    selectStepEvent(ctx: StateContext<EditorStateModel>): void {
        this.log(`[EVENT] SelectStepEvent (sel.idx=${ctx.getState().selectedStepIndex})`);
    }

    // ----------------------------------------------------------------------- Toolbar main menu actions
    @Action(ToolbarMenuActions.ExitEditor)
    exitEditor(ctx: StateContext<EditorStateModel>) {
        this.log(`[ACTION] ExitEditor`);
        ctx.patchState({
            mode: EditorMode.Exited
        });

        this.auth.logout().subscribe(async () => {
            await this.router.navigateByUrl('/login');
        });
    }

    // ----------------------------------------------------------------------- Components toolbar actions & flows
    @Action(EditorActions.AddStaticImage)
    addStaticImage(ctx: StateContext<EditorStateModel>) {
        this.log(`[ACTION] AddStaticImage (new flow)`);
        const state = ctx.getState();
        ctx.patchState({
            flowSettings: {
                flow: EditorFlow.AddImageObject,
                uploadedFile: undefined,
                selectedTrainingStepId: undefined,
                selectedEditorObjectId: undefined,
                sourceTriggerObjectId: state.selectedEditorObjectId,
                sourceTriggerStepId: state.selectedStepId
            }
        });
    }

    @Action(EditorActions.AddStaticImageEvent)
    addStaticImageEvent(ctx: StateContext<EditorStateModel>, action: EditorActions.AddStaticImageEvent) {
        this.log(`[EVENT] AddStaticImageEvent (new id = ${action.newObjectId})`);
    }

    @Action(EditorActions.SelectParentObject)
    selectParentObject(ctx: StateContext<EditorStateModel>, action: EditorActions.SelectParentObject) {
        this.log(`[ACTION] SelectParentObject (new flow)`);
        const state = ctx.getState();
        ctx.patchState({
            mode: EditorMode.ParentObjectSelection,
            flowSettings: {
                flow: EditorFlow.SelectParentObject,
                uploadedFile: undefined,
                selectedTrainingStepId: undefined,
                selectedEditorObjectId: undefined,
                sourceTriggerObjectId: action.childObjectId,
                sourceTriggerStepId: state.selectedStepId
            },
            hintBoxSettings: {
                isVisible: true,
                isExpanded: true,
                title: 'Wybierz nowy obiekt nadrzędny klikając w niego',
                message: 'Obiektem nadrzędnym może być obraz lub pulpit. Wszystkie interaktywne obiekty wraz z aktualnym i jego podrzędnymi zostały tymczasowo ukryte.'
            }
        });
    }

    @Action(EditorActions.SelectParentObjectEvent)
    selectParentObjectEvent(ctx: StateContext<EditorStateModel>, action: EditorActions.SelectParentObjectEvent) {
        this.log(`[EVENT] SelectParentObjectEvent (selected parent id = ${action.parentObjectId})`);
        const state = ctx.getState();
        ctx.patchState({
            mode: EditorMode.Default,
            flowSettings: {
                ...state.flowSettings,
                flow: EditorFlow.None,
                selectedEditorObjectId: _.isNull(action.parentObjectId) ? undefined : action.parentObjectId
            },
            hintBoxSettings: {
                isVisible: false,
                isExpanded: false,
                title: undefined,
                message: undefined
            }
        });
    }

    // ------------------------------------------------------------------------ Objects actions
    @Action(EditorActions.SelectObject)
    selectObject(ctx: StateContext<EditorStateModel>, action: EditorActions.SelectObject): Observable<void> {

        const editorObject = this.helper.getEditorObjectById(action.objectId);
        if (!editorObject) {
            return of();
        }

        const state = ctx.getState();
        this.log(`[ACTION] SelectObject (${action.objectId}) ${state.flowSettings.sourceTriggerObjectId}`);
        const selectedSameSourceObject = state.flowSettings.sourceTriggerObjectId == action.objectId;
        if (state.mode == EditorMode.ParentObjectSelection && !selectedSameSourceObject) {
            return ctx.dispatch(new EditorActions.SelectParentObjectEvent(action.objectId));
        }
        else if (state.mode == EditorMode.ParentObjectSelection && selectedSameSourceObject) {
            return of();
        }


        if (state.selectedEditorObjectId && action.objectId != state.selectedEditorObjectId) {
            this.log('Selection Change --->', state.selectedEditorObjectId, action.objectId);
            // deselect all objects first (because something was selected)
            return ctx.dispatch(new EditorActions.DeselectObjects())
                      .pipe(
                          tap(
                              // do the patch
                              () => {
                                  ctx.patchState({
                                      selectedEditorObjectId: action.objectId
                                  });
                              }
                          ),
                          switchMap(() => ctx.dispatch(new EditorActions.SelectObjectEvent(editorObject)))
                      );
        }
        else if (!state.selectedEditorObjectId) {
            ctx.patchState({
                selectedEditorObjectId: action.objectId
            });
            return ctx.dispatch(new EditorActions.SelectObjectEvent(editorObject));
        }
        return of();
    }

    @Action(EditorActions.SelectObjectEvent)
    selectObjectEvent(ctx: StateContext<EditorStateModel>) {
        this.log(`[EVENT] SelectObjectEvent (sel.obj.id=${ctx.getState().selectedEditorObjectId})`);
    }

    @Action(EditorActions.SelectObjectCompleteEvent)
    selectObjectCompleteEvent(ctx: StateContext<EditorStateModel>) {
        this.log(`[EVENT] SelectObjectCompleteEvent (sel.obj.id=${ctx.getState().selectedEditorObjectId})`);
    }

    @Action(EditorActions.DeselectObjects)
    deselectObjects(ctx: StateContext<EditorStateModel>): Observable<void> {
        this.log(`[ACTION] DeselectObjects`);
        ctx.patchState({
            selectedEditorObjectId: undefined
        });
        return ctx.dispatch(new EditorActions.DeselectObjectsEvent());
    }

    @Action(EditorActions.DeselectObjectsEvent)
    deselectObjectsEvent(ctx: StateContext<EditorStateModel>) {
        this.log(`[EVENT] DeselectObjectsEvent (sel.obj.id=${ctx.getState().selectedEditorObjectId})`);
    }

    @Action(EditorActions.ChangeObjectData)
    changeObjectData(ctx: StateContext<EditorStateModel>, action: EditorActions.ChangeObjectData): Observable<void> {
        this.log(`[ACTION] ChangeObjectData (${action.objectData.id})`);

        const patchObject = <UIEditorObjectData> {
            name: action.objectData.name,
            x: action.objectData.x,
            y: action.objectData.y,
            width: action.objectData.width,
            height: action.objectData.height,
            imageId: action.objectData.imageId,
            imageType: action.objectData.imageType,
            origin: action.objectData.origin,
            color: action.objectData.color,
            visible: action.objectData.visible,
            tooltip: action.objectData.tooltip,
            mouseOutActions: action.objectData.mouseOutActions,
            mouseInActions: action.objectData.mouseInActions,
            clickActions: action.objectData.clickActions
        };

        const objectIndex = this.helper.getObjectIndexById(action.objectData.id);
        const selectedStepIndex = ctx.getState().selectedStepIndex;
        ctx.setState(
            patch<EditorStateModel>({
                training: patch<UITraining>({
                    steps: updateItem<UITrainingStep>(selectedStepIndex, patch({
                        objects: updateItem<UIEditorObject>(objectIndex!, patch(patchObject))
                    }))
                })
            })
        );
        const updatedObject = this.helper.getEditorObjectById(action.objectData.id);
        return ctx.dispatch(new EditorActions.ChangeObjectDataEvent(updatedObject!));
    }

    @Action(EditorActions.ChangeObjectDataEvent)
    changeObjectDataEvent(ctx: StateContext<EditorStateModel>) {
        this.log(`[EVENT] ChangeObjectDataEvent (obj.id=${ctx.getState().selectedEditorObjectId})`);
    }

    @Action(EditorActions.ChangeObjectParent)
    changeObjectParent(ctx: StateContext<EditorStateModel>, action: EditorActions.ChangeObjectParent): Observable<void> {
        this.log(`[ACTION] ChangeObjectParent (child: ${action.childObjectId}, new parent: ${action.newParentId})`);
        const state = ctx.getState();

        const childObject = this.helper.getEditorObjectById(action.childObjectId)!;
        const childObjectIndex = this.helper.getObjectIndexById(action.childObjectId)!;
        const oldParentId = childObject.parentId;

        const oldParentIndex = oldParentId ? this.helper.getObjectIndexById(oldParentId) : undefined;
        const oldParent = oldParentId ? this.helper.getEditorObjectById(oldParentId) : undefined;

        const newParentIndex = action.newParentId ? this.helper.getObjectIndexById(action.newParentId) : undefined;
        const newParent = action.newParentId ? this.helper.getEditorObjectById(action.newParentId) : undefined;

        const selectedStepIndex = state.selectedStepIndex;

        // after assigning new object to it's parent, the coordinates of currently selected object
        // should change to make the object stay in place.
        const position = this.html.getRelativePositionTo(childObject.id, newParent?.id);

        const patchObject = <Partial<UIEditorObject>> {
            parentId: action.newParentId,
            x: position.x,
            y: position.y,

            // after changing parent we always set this origin, to be sure that our new position works
            origin: PlacementOrigin.LeftTop
        };

        // update child
        ctx.setState(
            patch<EditorStateModel>({
                training: patch<UITraining>({
                    steps: updateItem<UITrainingStep>(selectedStepIndex, patch({
                        objects: updateItem<UIEditorObject>(childObjectIndex, patch(patchObject))
                    }))
                })
            })
        );

        // update old parent if not desktop
        if (oldParent) {
            const newChildren = [...oldParent.children!];
            _.pull(newChildren, action.childObjectId);

            ctx.setState(
                patch<EditorStateModel>({
                    training: patch<UITraining>({
                        steps: updateItem<UITrainingStep>(selectedStepIndex, patch({
                            objects: updateItem<UIEditorObject>(oldParentIndex!, patch(
                                <Partial<UIEditorObject>> {
                                    children: newChildren
                                }
                            ))
                        }))
                    })
                })
            );
        }

        // update new parent if not desktop
        if (newParent) {
            const newChildren = [
                ...(newParent.children || []),
                action.childObjectId
            ];

            ctx.setState(
                patch<EditorStateModel>({
                    training: patch<UITraining>({
                        steps: updateItem<UITrainingStep>(selectedStepIndex, patch({
                            objects: updateItem<UIEditorObject>(newParentIndex!, patch(
                                <Partial<UIEditorObject>> {
                                    children: newChildren
                                }
                            ))
                        }))
                    })
                })
            );
        }

        return ctx.dispatch(new EditorActions.ChangeObjectParentEvent(
            action.childObjectId, action.newParentId, oldParentId
        ));
    }

    @Action(EditorActions.ChangeObjectParentEvent)
    changeObjectParentEvent(ctx: StateContext<EditorStateModel>, action: EditorActions.ChangeObjectParentEvent) {
        this.log(`[EVENT] ChangeObjectParentEvent (child = ${action.childObjectId}, old parent = ${action.oldParentId}, new parent = ${action.newParentId})`);
    }

    @Action(EditorActions.DisableObject)
    disableObject(ctx: StateContext<EditorStateModel>, action: EditorActions.DisableObject): void {
        this.log(`[ACTION] DisableObject (${action.id})`);
    }

    @Action(EditorActions.EnableObject)
    enableObject(ctx: StateContext<EditorStateModel>, action: EditorActions.EnableObject): void {
        this.log(`[ACTION] EnableObject (${action.id})`);
    }

    @Action(EditorActions.CreateEditorObject)
    createEditorObject(ctx: StateContext<EditorStateModel>, action: EditorActions.CreateEditorObject): Observable<void> {
        this.log(`[ACTION] CreateEditorObject (${action.objectData.type})`);
        const state = ctx.getState();
        const newObject = _.cloneDeep(action.objectData);
        newObject.id = uuid.v4();
        const newIndex = state.training.steps[state.selectedStepIndex].objects.length;
        ctx.setState(
            patch<EditorStateModel>({
                training: patch<UITraining>({
                    steps: updateItem<UITrainingStep>(state.selectedStepIndex, patch({
                        objects: append<UIEditorObject>([newObject]),
                        objectsById: patch<{ [objectId: string]: number }>({
                            ...state.training.steps[state.selectedStepIndex].objectsById,
                            [newObject.id]: newIndex
                        })
                    }))
                })
            })
        );
        return ctx.dispatch(new EditorActions.CreateEditorObjectEvent(newObject.id));
    }

    @Action(EditorActions.CreateEditorObjectEvent)
    createEditorObjectEvent(ctx: StateContext<EditorStateModel>, action: EditorActions.CreateEditorObjectEvent): void {
        this.log(`[EVENT] CreateEditorObjectEvent (id=${action.newObjectId})`);
    }

    // ----------------------------------------------------------------------- Editor actions
    @Action(EditorActions.LoadTraining)
    loadTraining(ctx: StateContext<EditorStateModel>, action: EditorActions.LoadTraining): Observable<void> {
        this.log(`[ACTION] LoadTraining (${action.trainingId})`);
        return this.loader.loadTraining(action.trainingId)
                   .pipe(
                       tap(training => {
                           ctx.patchState({
                               training: {...training},
                               selectedStepIndex: training.steps.length > 0 ? 0 : -1,
                               selectedEditorObjectId: undefined,
                               selectedStepId: training.steps.length > 0 ? training.steps[0].id : undefined,
                               mode: EditorMode.Default,
                               hintBoxSettings: {
                                   isExpanded: false,
                                   isVisible: false,
                                   message: undefined,
                                   title: undefined
                               },
                               flowSettings: {
                                   flow: EditorFlow.None,
                                   selectedEditorObjectId: undefined,
                                   sourceTriggerStepId: undefined,
                                   sourceTriggerObjectId: undefined,
                                   selectedTrainingStepId: undefined,
                                   uploadedFile: undefined
                               }
                           });
                       }),
                       switchMap(() => {
                           const state = ctx.getState();
                           const selectedStepIndex = state.selectedStepIndex;
                           const training = state.training;
                           return ctx.dispatch(new EditorActions.LoadTrainingEvent(training, selectedStepIndex));
                       })
                   );
    }

    @Action(EditorActions.LoadTrainingEvent)
    loadTrainingEvent(ctx: StateContext<EditorStateModel>, action: EditorActions.LoadTrainingEvent): void {
        this.log(`[EVENT] LoadTrainingEvent (training.id=${action.training.id})`);
    }

    // ------------------------------------------------------------------------ Object handles actions
    @Action(EditorActions.UpdateObjectHandles)
    updateObjectHandles(ctx: StateContext<EditorStateModel>, action: EditorActions.UpdateObjectHandles): Observable<void> {
        this.log(`[ACTION] UpdateObjectHandles`);
        return ctx.dispatch(new EditorActions.UpdateObjectHandlesEvent(action.settings));
    }

    @Action(EditorActions.UpdateObjectHandlesEvent)
    updateObjectHandlesEvent(ctx: StateContext<EditorStateModel>) {
        this.log(`[EVENT] UpdateObjectHandlesEvent`);
    }

    @Action(EditorActions.ObjectHandleLeftClick)
    objectHandleLeftClick(ctx: StateContext<EditorStateModel>, action: EditorActions.ObjectHandleLeftClick) {
        this.log(`[ACTION] ObjectHandleLeftClick (pos. ${action.position})`);
        return ctx.dispatch(new EditorActions.ObjectHandleLeftClickEvent(action.position));
    }

    @Action(EditorActions.ObjectHandleRightClick)
    objectHandleRightClick(ctx: StateContext<EditorStateModel>, action: EditorActions.ObjectHandleRightClick) {
        this.log(`[ACTION] ObjectHandleRightClick (pos. ${action.position})`);
        return ctx.dispatch(new EditorActions.ObjectHandleRightClickEvent(action.position));
    }

    @Action(EditorActions.ObjectHandleLeftClickEvent)
    objectHandleLeftClickEvent(ctx: StateContext<EditorStateModel>, action: EditorActions.ObjectHandleLeftClickEvent) {
        this.log(`[EVENT] ObjectHandleLeftClickEvent (pos. ${action.position})`);
    }

    @Action(EditorActions.ObjectHandleRightClickEvent)
    objectHandleRightClickEvent(ctx: StateContext<EditorStateModel>, action: EditorActions.ObjectHandleRightClickEvent) {
        this.log(`[EVENT] ObjectHandleRightClickEvent (pos. ${action.position})`);
    }

    // ------------------------------------------------------------------------ Editor Flow Actions

    private wrapStepChanges(ctx: StateContext<EditorStateModel>, update: () => void): Observable<void> {
        ctx.dispatch(new EditorActions.DeselectObjects());
        return this.actions
                   .pipe(
                       ofActionCompleted(EditorActions.DeselectObjectsEvent),
                       take(1),
                       tap(() => update()),
                       switchMap(() => {
                           const newState = ctx.getState();
                           return ctx.dispatch(new ToolbarStepsActions.SelectStepEvent(
                               newState.selectedStepIndex,
                               newState.selectedStepId
                           ));
                       })
                   );
    }

    private log(...args: any[]): void {
        if (args && args.length === 1) {
            console.log(args[0]);
        }
        else {
            console.log(args);
        }
    }
}
