import {Component, OnDestroy, OnInit} from '@angular/core';
import {Actions, ofActionDispatched, Store} from '@ngxs/store';
import {ObjectColor} from 'common-lib';
import {takeUntil} from 'rxjs/operators';
import {UiEditorObjectType} from '../../../models/ui-editor-object-type.enum';
import {UIEditorObject} from '../../../models/ui-editor-object.model';
import {EventBusService} from '../../../services/event-bus.service';
import {HtmlHelperService} from '../../../services/html-helper.service';
import {ModalsService} from '../../../services/modals.service';
import {StoreHelperService} from '../../../services/store-helper.service';
import {EditorActions} from '../../../store/editor.actions';

@Component({
    selector: 'ip-object-settings-icon-handle',
    templateUrl: './object-settings-icon-handle.component.html',
    styleUrls: ['./object-settings-icon-handle.component.scss']
})
export class ObjectSettingsIconHandleComponent implements OnInit, OnDestroy {
    /**
     * Defines an icon theme color.
     */
    public color: ObjectColor = ObjectColor.Yellow;

    constructor(private bus: EventBusService,
                private html: HtmlHelperService,
                private helper: StoreHelperService,
                private modals: ModalsService,
                private store: Store,
                private actions: Actions
    ) {
    }

    public ngOnInit(): void {
        const selectedObject = this.helper.getSelectedObject();
        if (selectedObject) {
            this.color = selectedObject.color;
        }
    }

    public ngOnDestroy(): void {

    }

    public onClick(event: MouseEvent): void {
        event.stopPropagation();
        const selectedObject = this.helper.getSelectedObject()!;

        // show image settings for image object
        if (selectedObject.type === UiEditorObjectType.Image) {
            this.store.dispatch(new EditorActions.DeselectObjects());

            this.modals.showImageObjectSettings(selectedObject)
                .subscribe((updatedObject: UIEditorObject) => {
                    this.store.dispatch(new EditorActions.ChangeObjectData(updatedObject));
                    this.color = updatedObject.color;

                    // handle parent-child hierarchy changes
                    if (selectedObject.parentId != updatedObject.parentId) {
                        const subscription$ = this.actions
                                                  .pipe(
                                                      ofActionDispatched(EditorActions.EditorObjectsRendered)
                                                  )
                                                  .subscribe(() => {
                                                      subscription$.unsubscribe();
                                                      this.reselectObjectById(selectedObject.id);
                                                  });

                        this.store.dispatch(new EditorActions.ChangeObjectParent(selectedObject.id, updatedObject.parentId));
                    }
                    else {
                        this.reselectObjectById(selectedObject.id);
                    }
                });
        }
    }

    private reselectObjectById(selectedObjectId: string): void {

        // TODO: we have to wait here if hierarchy was changed to get all items re-rendered.
        //       We can think about better solution than this
        setTimeout(() => {
            // select again original object
            this.store.dispatch(new EditorActions.SelectObject(selectedObjectId));
        }, 500);

    }
}
