import {Injectable} from '@angular/core';
import {ActionCompletion, Actions, ofActionCompleted, ofActionDispatched, Store} from '@ngxs/store';
import {FileModel, PlacementOrigin} from 'common-lib';
import {Observable, of, Subject} from 'rxjs';
import {first, mergeMap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {DEFAULT_OBJECT_COLOR} from '../../constants';
import {UiEditorObjectType} from '../../models/ui-editor-object-type.enum';
import {UIEditorObject} from '../../models/ui-editor-object.model';
import {EditorActions} from '../../store/editor.actions';
import {FileUploaderActions} from '../../store/file-uploader.actions';

@Injectable({providedIn: 'root'})
export class AddImageObjectFlowService {

    constructor(private actions$: Actions, private store: Store) {
        this.actions$.pipe(ofActionCompleted(EditorActions.AddStaticImage))
            .subscribe(() => {
                this.addStaticImageHandler();
            });


    }

    private addStaticImageHandler(): void {
        this.store.dispatch(new FileUploaderActions.Show());

        // handle object creation from uploaded file
        const saveSubscription = this.actions$
                                     .pipe(
                                         ofActionCompleted(FileUploaderActions.Save),
                                         first(),
                                         mergeMap((result: ActionCompletion<FileUploaderActions.Save, Error>) => this.getNewImageObjectModel(result.action.file)),
                                         mergeMap((newUIObject: UIEditorObject) => this.store.dispatch(new EditorActions.CreateEditorObject(newUIObject)))
                                     )
                                     .subscribe(() => {
                                         cancelSubscription.unsubscribe();
                                     });

        // handle cancel file upload finalization
        const cancelSubscription = this.actions$
                                       .pipe(
                                           ofActionCompleted(FileUploaderActions.Cancel),
                                           first(),
                                           mergeMap(() => this.store.dispatch(new EditorActions.AddStaticImageEvent(undefined)))
                                       )
                                       .subscribe(() => {
                                           saveSubscription.unsubscribe();
                                           addImageSubscription.unsubscribe();
                                       });

        // handle flow finalization
        const addImageSubscription = this.actions$
                                         .pipe(
                                             ofActionDispatched(EditorActions.CreateEditorObjectEvent),
                                             first(),
                                             mergeMap((action: EditorActions.CreateEditorObjectEvent) => of(action.newObjectId)),

                                             // finalization event
                                             mergeMap(newObjectId => this.store.dispatch(new EditorActions.AddStaticImageEvent(newObjectId)))
                                         )
                                         .subscribe(() => {
                                             cancelSubscription.unsubscribe();
                                             addImageSubscription.unsubscribe();
                                         });
    }


    private getNewImageObjectModel(image: FileModel): Observable<UIEditorObject> {
        const result = new Subject<UIEditorObject>();
        const newObject = <UIEditorObject> {
            imageId: image.id,
            type: UiEditorObjectType.Image,
            x: 0,
            y: 0,
            origin: PlacementOrigin.Center,
            color: DEFAULT_OBJECT_COLOR,
            imageType: image.mimeType
        };
        const imageObject = new Image();
        imageObject.onload = function() {
            newObject.width = (<any> this).width;
            newObject.height = (<any> this).height;
            result.next(newObject);
            result.complete();
        };
        imageObject.onerror = (...args: any[]) => {
            console.log(args);
            result.error(new Error('Error reading image data, to check dimensions.'));
            result.complete();
        };
        imageObject.src = `${environment.filesUrl}/${image.id}`;
        return result.asObservable();
    }
}
