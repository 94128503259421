<div class="editor-host d-flex flex-column justify-content-start h-100">
    <ip-editor-toolbar class="toolbar" (onExpandedChanged)="toolbarExpandedChanged($event)"
                       [disabled]="isEditorToolbarDisabled"></ip-editor-toolbar>
    <ip-windows-desktop class="flex-grow-1" [disabledToolbar]="isDesktopToolbarDisabled">

        <ng-container *ngFor="let object of (objects$ | async)">
            <!--   STATIC OBJECTS     -->
            <ip-image-object #editorObject *ngIf="object.type === UIEditorObjectType.Image && !object.parentId"
                             [objectModel]="object">
            </ip-image-object>

            <!--   INTERACTIVE OBJECTS     -->
            <!--  <ip-interactive-object #editorObject></ip-interactive-object>      -->


        </ng-container>

        <!--   FILE UPLOADER     -->
        <ip-file-uploader overlay *ngIf="(editorMode$ | async) === EditorMode.FileUpload"
                          (onCancel)="onUploaderCancel()" (onSave)="onUploaderSave($event)"></ip-file-uploader>

        <!--   HINT BOX     -->
        <div *ngIf="(hintBoxSettings$ | async)?.isVisible" [style.margin-top]="isToolbarExpanded ? '110px' : '0'"
             style="position: relative">
            <ip-hint-box
                [message]="(hintBoxSettings$ | async)?.message"
                [title]="(hintBoxSettings$ | async)?.title"
                [isExpanded]="(hintBoxSettings$ | async)?.isExpanded || false"
            ></ip-hint-box>
        </div>
    </ip-windows-desktop>
</div>
