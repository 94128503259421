import {AgentActionModel} from '../agents/agent-action.model';
import {ObjectActionType} from './object-action-type.enum';
import {TooltipModel} from './tooltip.model';

export interface ObjectActionModel {

    /**
     * The type of action to run.
     */
    type: ObjectActionType;
    /**
     * Optional tooltip to show for {@link ObjectActionType.ShowTooltip} action type.
     */
    tooltip?: TooltipModel;
    /**
     * Optional target step ID to navigate to for {@link ObjectActionType.GoToStep} action type.
     */
    targetStep?: string;
    /**
     * Optional agent action model for {@link ObjectActionType.ExecuteAgent} action type.
     * Defined an action to perform on current agent character. As an reaction agent
     * character will do something.
     */
    agentAction?: AgentActionModel;
}
