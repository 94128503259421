<div class="toolbar-container" [class.show]="isExpanded" [class.disabled]="disabled">
    <ip-menu [show]="showLeftMenu" (hideMenu)="menuOverlayClicked()">
        <div class="menu-item" (click)="onMenuClick(MenuItemType.NewTraining)">Nowy kurs</div>
        <div class="menu-item" (click)="onMenuClick(MenuItemType.SaveTraining)">Zapisz</div>
        <div class="menu-item" (click)="onMenuClick(MenuItemType.TrainingProperties)">Właściwości</div>
        <div class="menu-item" (click)="onMenuClick(MenuItemType.ExitEditor)">Zamknij edytor</div>
    </ip-menu>
    <ip-menu [show]="showRightMenu" [position]="menuPosition.right" (hideMenu)="menuOverlayClicked()">
        <div
            class="menu-item"
            (click)="onStepMenuClicked(StepsMenuItemType.MoveToEnd)"
            [ngClass]="{ disabled: !stepsParams.selected || stepsParams.lastSelected }"
        >
            Przenieś na koniec
        </div>
        <div
            class="menu-item"
            (click)="onStepMenuClicked(StepsMenuItemType.MoveToBegin)"
            [ngClass]="{ disabled: !stepsParams.selected || stepsParams.firstSelected }"
        >
            Przenieś na początek
        </div>
        <div class="menu-item" (click)="onStepMenuClicked(StepsMenuItemType.Remove)"
             [ngClass]="{ disabled: !stepsParams.selected }">Usuń
        </div>
        <div class="menu-item" (click)="onStepMenuClicked(StepsMenuItemType.Duplicate)"
             [ngClass]="{ disabled: !stepsParams.selected }">
            Duplikuj
        </div>
        <div class="menu-item" [ngClass]="{ disabled: !stepsParams.selected }">Resetuj</div>
        <div class="menu-item" (click)="onStepMenuClicked(StepsMenuItemType.RemoveAll)"
             [ngClass]="{ disabled: stepsParams.count === 0 }">
            Usuń wszystkie
        </div>
    </ip-menu>
    <div class="toolbar d-flex flex-start align-items-center">
        <ip-menu-trigger
            class="h-100"
            [selected]="showLeftMenu"
            (selectedChange)="showLeftMenu = $event"
        ></ip-menu-trigger>
        <div class="controls d-flex flex-wrap">
            <a href="javascript:void(0)" (click)="onToolbarClicked(ToolbarItemType.AddStaticImage)"
               data-bs-toggle="tooltip" data-bs-placement="top" title="Dodaj statyczny obrazek">
                <i class="bi bi-image"></i>
            </a>
            <a href="javascript:void(0)" (click)="onToolbarClicked(ToolbarItemType.AddTooltip)"
               title="Dodaj tooltip">
                <i class="bi bi-chat-square-text"></i>
            </a>

        </div>
        <ng-scrollbar track="horizontal" class="scrollbar d-flex flex-grow-1 h-100">
            <div class="steps d-flex align-items-center h-100">
                <ip-navbar-step [stepType]="stepTypes.Start"></ip-navbar-step>
                <div class="step-container d-flex" *ngFor="let step of editorSteps$ | async; index as i">
                    <div class="add-step" (click)="addStepAtIndex(i)"></div>
                    <ip-navbar-step [stepType]="stepTypes.Position" [index]="i"></ip-navbar-step>
                </div>
                <div class="step-placeholder" (click)="addStep()"><i class="icon bi bi-plus-lg"></i></div>
                <div class="horizontal-line"></div>
            </div>
        </ng-scrollbar>
        <ip-menu-trigger
            class="h-100"
            [selected]="showRightMenu"
            [dotsIcon]="true"
            (selectedChange)="showRightMenu = $event"
        ></ip-menu-trigger>
    </div>

    <div class="toggle-button d-flex justify-content-center">
        <button (click)="toggleCollapse()" class="collapse-button" type="button">
            <i class="bi {{ isExpanded ? 'bi-caret-up-fill' : 'bi-caret-down-fill' }}"></i>
        </button>
    </div>
</div>

