import {EnumDictionary} from 'common-lib';
import {HandleSettings} from '../components/editor-objects/handles-settings.interface';
import {HandlePosition} from '../models/handle-position.enum';
import {UIEditorObject} from '../models/ui-editor-object.model';
import {UITraining} from '../models/ui-training.model';

export namespace EditorActions {
    export class LoadTraining {
        static readonly type = '[Editor] Load training';

        constructor(public trainingId: string) {
        }
    }

    export class LoadTrainingEvent {
        static readonly type = '[Editor] Load training EVENT';

        constructor(public training: UITraining, public selectedStepIndex?: number) {
        }
    }

    export class SelectObject {
        static readonly type = '[EditorObjects] Select editor object';

        constructor(public objectId: string) {
        }
    }

    export class SelectObjectEvent {
        static readonly type = '[EditorObjects] Select editor object EVENT';

        constructor(public object: UIEditorObject) {
        }
    }

    /**
     * Executed when whole component and it's handles have been initialized and
     * set into selected state.
     * After that event we can e.g. set initial handles state.
     */
    export class SelectObjectCompleteEvent {
        static readonly type = '[EditorObjects] Select editor object complete EVENT';

        constructor(public object: UIEditorObject) {
        }
    }

    export class DesktopClick {
        static readonly type = '[EditorDesktop] Click';
    }

    export class DeselectObjects {
        static readonly type = '[EditorObjects] Deselect all editor objects';
    }

    export class DeselectObjectsEvent {
        static readonly type = '[EditorObjects] Deselect all editor objects EVENT';
    }

    /**
     * Updates editor static data, like position or size.
     * NOTE: This action doesn't update hierarchy, can't change the parent.
     */
    export class ChangeObjectData {
        static readonly type = '[EditorObjects] Change editor object data';

        constructor(public objectData: UIEditorObject) {
        }
    }

    export class ChangeObjectDataEvent {
        static readonly type = '[EditorObjects] Change editor object data EVENT';

        constructor(public object: UIEditorObject) {
        }
    }

    /**
     * Updates editor object parent-child relation - changes the parent of the object.
     */
    export class ChangeObjectParent {
        static readonly type = '[EditorObjects] Change editor object parent';

        constructor(public childObjectId: string, public newParentId: string | undefined) {
        }
    }

    export class ChangeObjectParentEvent {
        static readonly type = '[EditorObjects] Change editor object parent EVENT';

        constructor(
            public childObjectId: string,
            public newParentId: string | undefined,
            public oldParentId: string | undefined
        ) {
        }
    }

    /**
     * Disables the object, makes it semitransparent and not-clickable.
     * All child objects are also being disabled.
     */
    export class DisableObject {
        static readonly type = '[EditorObjects] Disable';

        constructor(public id: string) {
        }
    }

    /**
     * Enables back the disabled object.
     */
    export class EnableObject {
        static readonly type = '[EditorObjects] Enable';

        constructor(public id: string) {
        }
    }

    export class CreateEditorObject {
        static readonly type = '[EditorObjects] Create new object in editor';

        constructor(public objectData: UIEditorObject) {
        }
    }

    export class CreateEditorObjectEvent {
        static readonly type = '[EditorObjects] Create new object in editor EVENT';

        constructor(public newObjectId: string) {
        }
    }

    export class ObjectHandleLeftClick {
        static readonly type = '[ObjectHandles] Object handle left mouse click';

        constructor(public position: HandlePosition) {
        }
    }

    export class ObjectHandleRightClick {
        static readonly type = '[ObjectHandles] Object handle right mouse click';

        constructor(public position: HandlePosition) {
        }
    }

    export class ObjectHandleLeftClickEvent {
        static readonly type = '[ObjectHandles] Object handle left mouse click EVENT';

        constructor(public position: HandlePosition) {
        }
    }

    export class ObjectHandleRightClickEvent {
        static readonly type = '[ObjectHandles] Object handle right mouse click EVENT';

        constructor(public position: HandlePosition) {
        }
    }

    export class UpdateObjectHandles {
        static readonly type = '[ObjectHandles] Update object handles';

        constructor(public settings: EnumDictionary<HandlePosition, HandleSettings>) {
        }
    }

    export class UpdateObjectHandlesEvent {
        static readonly type = '[ObjectHandles] Update object handles EVENT';

        constructor(public settings: EnumDictionary<HandlePosition, HandleSettings>) {
        }
    }

    export class AddStaticImage {
        static readonly type = '[EditorToolbar] Add static image';
    }

    export class AddStaticImageEvent {
        static readonly type = '[EditorToolbar] Add static image EVENT';

        /**
         * Missing id means, that the flow was cancelled.
         * @param newObjectId
         */
        constructor(public newObjectId?: string) {
        }
    }

    /**
     * Enables the flow for selecting an object that can be a parent e.g. Static Image Object.
     */
    export class SelectParentObject {
        static readonly type = '[EditorToolbar] Select parent object';

        /**
         * Selects parent object for given child object.
         * @param childObjectId THe id of the object which parent is being selected.
         */
        constructor(public childObjectId: string) {
        }
    }

    export class SelectParentObjectEvent {
        static readonly type = '[EditorToolbar] Select parent object EVENT';

        /**
         * Missing id (`undefined`) means, that the flow was cancelled, null means Desktop.
         * @param parentObjectId
         */
        constructor(public parentObjectId: string | undefined | null) {
        }
    }

    /**
     * Fired when all editor objects was rendered or re-rendered and are available in DOM.
     */
    export class EditorObjectsRendered {
        static readonly type = '[EditorObjects] Rendered';
    }
}
