export namespace ToolbarMenuActions {
    // /**
    //  * Creates a new training in editor and:
    //  * - verifies that the training changes are saved, if not show modal with saving question.
    //  */
    // export class NewTraining {
    //     static readonly type = '[Editor Toolbar Menu] New training';
    // }
    //
    // /**
    //  * Saves currently opened training changes.
    //  */
    // export class SaveTraining {
    //     static readonly type = '[Editor Toolbar Menu] Save training';
    // }
    //
    // /**
    //  * Opens a modal with training properties.
    //  */
    // export class TrainingProperties {
    //     static readonly type = '[Editor Toolbar Menu] Show training properties';
    // }

    /**
     * Exits editor and:
     * - verifies that the training changes are saved, if not show modal with saving question,
     * - logouts the user,
     * - redirects back to login screen.
     */
    export class ExitEditor {
        static readonly type = '[Editor Toolbar Menu] Exit editor / Logout';
    }
}
