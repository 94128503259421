import {CommonModule} from '@angular/common';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsModule} from '@ngxs/store';
import {ClipboardModule} from 'ngx-clipboard';
import {ModalDialogModule} from 'ngx-modal-dialog';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {CommonLibModule, AppEnvironment, CommonLibConfig} from 'common-lib';
import {environment} from '../../environments/environment';
import {EditorToolbarComponent} from './components/editor-toolbar/editor-toolbar.component';
import {MenuTriggerComponent} from './components/menu-trigger/menu-trigger.component';
import {MenuComponent} from './components/menu/menu.component';
import {NavbarStepComponent} from './components/navbar-step/navbar-step.component';
import {WindowsClockComponent} from './components/windows-clock/windows-clock.component';
import {WindowsDesktopComponent} from './components/windows-desktop/windows-desktop.component';
import {EditorHostComponent} from './components/editor-host/editor-host.component';
import {EditorRoutingModule} from './editor-routing.module';
import {AddImageObjectFlowService} from './services/flows/add-image-object.flow-service';
import {EditorReducers} from './store/editor.reducers';
import {HintBoxComponent} from './components/hint-box/hint-box.component';
import {ImageObjectComponent} from './components/editor-objects/image-object/image-object.component';
import {AnchorHandleComponent} from './components/editor-objects/anchor-handle/anchor-handle.component';
import {ImageObjectSettingsModal} from './components/editor-objects/image-object-settings-modal/image-object-settings.modal';
import {ObjectValueSettingComponent} from './components/editor-objects/object-value-setting/object-value-setting.component';
import {ObjectSelectorSettingComponent} from './components/editor-objects/object-selector-setting/object-selector-setting.component';
import {ObjectColorSettingComponent} from './components/editor-objects/object-color-setting/object-color-setting.component';
import {
    ObjectSettingsIconHandleComponent
} from './components/editor-objects/object-settings-icon-handle/object-settings-icon-handle.component';
import {ObjectInputSettingComponent} from './components/editor-objects/object-input-setting/object-input-setting.component';

// Noop handler for factory function
export function noop() {
    return function() {
    };
}

@NgModule({
    declarations: [
        EditorHostComponent,
        EditorToolbarComponent,
        WindowsClockComponent,
        WindowsDesktopComponent,
        NavbarStepComponent,
        MenuComponent,
        MenuTriggerComponent,
        HintBoxComponent,
        ImageObjectComponent,
        AnchorHandleComponent,
        ImageObjectSettingsModal,
        ObjectValueSettingComponent,
        ObjectSelectorSettingComponent,
        ObjectColorSettingComponent,
        ObjectSettingsIconHandleComponent,
        ObjectInputSettingComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        EditorRoutingModule,
        ClipboardModule,
        CommonLibModule.forRoot(
            new CommonLibConfig({
                apiUrl: environment.apiUrl,
                filesUrl: environment.filesUrl,
                environment: environment.production ? AppEnvironment.Production : AppEnvironment.Test
            })
        ),
        NgxsModule.forRoot([
            EditorReducers
        ], {developmentMode: !environment.production}),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        NgScrollbarModule,
        ModalDialogModule.forRoot()
    ],
    exports: [EditorHostComponent],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: noop,
            deps: [
                AddImageObjectFlowService
            ],
            multi: true
        }
    ]
})
export class EditorModule {
}
