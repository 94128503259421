import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from 'common-lib';

@Component({
    selector: 'ip-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(private router: Router, private auth: AuthService) {
    }

    public ngOnInit(): void {
        this.auth.isCurrentSessionValid().subscribe((isValid: boolean) => {
            if (!isValid) {
                this.auth.logout().subscribe(() => {
                    this.router.navigateByUrl('/login');
                });
            }
        });
    }
}
