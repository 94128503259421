import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EditorHostComponent} from './components/editor-host/editor-host.component';

const routes: Routes = [
    {
        path: '', component: EditorHostComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class EditorRoutingModule {
}
