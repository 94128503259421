import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'ip-menu-trigger',
    templateUrl: './menu-trigger.component.html',
    styleUrls: ['./menu-trigger.component.scss']
})
export class MenuTriggerComponent {
    @Input() selected = false;
    @Input() dotsIcon = false;
    @Output() selectedChange = new EventEmitter<boolean>();

    onMenuButtonClick(): void {
        this.selected = !this.selected;
        this.selectedChange.emit(this.selected);
    }

    onOverlayClick(): void {
        if (!this.selected) {
            return;
        }
        this.selected = false;
        this.selectedChange.emit(this.selected);
    }
}
