import {EventEmitter, Injectable, NgZone} from '@angular/core';
import {Actions, ofActionDispatched, Store} from '@ngxs/store';
import {Subject} from 'rxjs';
import {PlacementOrigin} from 'common-lib';
import {takeUntil} from 'rxjs/operators';
import {BUS_EVENT_OBJECT_MOVE, MOVEMENT_EDGE_MARGIN} from '../constants';
import {UIEditorObject} from '../models/ui-editor-object.model';
import {UiPoint} from '../models/ui-point.model';
import {VisualPositioning} from '../models/visual-positioning';
import {EditorActions} from '../store/editor.actions';
import {EventBusService} from './event-bus.service';
import {HtmlHelperService} from './html-helper.service';

@Injectable({
    providedIn: 'root'
})
export class DraggingService {

    private unsubscribe$ = new Subject();
    /**
     * Represent currently selected object data model.
     * @private
     */
    private currentObject?: UIEditorObject;
    /**
     * Represents currently selected object parent HTML native element.
     * @private
     */
    private currentObjectParentElement?: HTMLElement;
    /**
     * Represents currently selected object HTML native element.
     * @private
     */
    private currentObjectElement?: HTMLElement;
    /**
     * Informs if original current object model was changed by this service.
     * This is used to determine if object data was changed and should be reported to update.
     * @private
     */
    private currentObjectChanged: boolean = false;
    private unlistenClick: (() => void) | undefined;
    private unlistenMouseDown: (() => void) | undefined;
    private unlistenMouseMove: (() => void) | undefined;
    private unlistenMouseUp: (() => void) | undefined;
    private unlistenArrowUp: (() => void) | undefined;
    private unlistenArrowDown: (() => void) | undefined;
    private unlistenArrowLeft: (() => void) | undefined;
    private unlistenArrowRight: (() => void) | undefined;
    private mouseStartDrag!: { x: number, y: number, left: number, top: number };
    private mousePos!: { x: number, y: number };

    public onSelectionChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    public onMoved: EventEmitter<void> = new EventEmitter<void>();
    public onMoving: EventEmitter<void> = new EventEmitter<void>();

    constructor(private ngZone: NgZone,
                private store: Store,
                private actions: Actions,
                private html: HtmlHelperService,
                private bus: EventBusService) {

        // handle selection
        this.actions
            .pipe(
                takeUntil(this.unsubscribe$),
                ofActionDispatched(EditorActions.SelectObjectEvent)
            )
            .subscribe((ctx: EditorActions.SelectObjectEvent) => {
                this.onObjectSelected(ctx.object);
            });

        // handle deselection
        this.actions
            .pipe(
                takeUntil(this.unsubscribe$),
                ofActionDispatched(EditorActions.DeselectObjectsEvent)
            )
            .subscribe(() => {
                this.onObjectsDeselected();
            });

        // handle object data changes
        this.actions
            .pipe(
                takeUntil(this.unsubscribe$),
                ofActionDispatched(EditorActions.ChangeObjectDataEvent)
            )
            .subscribe((ctx: EditorActions.ChangeObjectDataEvent) => {
                if (this.currentObject && this.currentObject.id === ctx.object.id) {
                    this.onObjectDataChanged(ctx.object);
                }
            });
    }

    public destroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        if (this.unlistenClick) {
            this.unlistenClick();
            this.unlistenClick = undefined;
        }
        this.onMoved.complete();
        this.onSelectionChanged.complete();
    }

    private onObjectDataChanged(object: UIEditorObject): void {
        this.currentObject = object;
        this.currentObjectChanged = false;
    }

    private onObjectSelected(object: UIEditorObject): void {
        this.currentObjectChanged = false;
        this.currentObject = object;
        this.currentObjectElement = this.html.findObjectContainer(object.id);
        this.currentObjectParentElement = this.html.findParentObject(this.currentObjectElement, this.currentObject.parentId);

        let isMouseDown = false;

        // skip Angular Zone change detection for all that events
        this.ngZone.runOutsideAngular(() => {
            this.unlistenMouseDown = this.html.attachMouseDown(this.currentObjectElement as HTMLElement, (event: MouseEvent) => {

                isMouseDown = true;
                event.stopPropagation();
                const startXY = this.calculateDragStartingPoint();

                this.mouseStartDrag = {
                    x: event.clientX,
                    y: event.clientY,
                    left: startXY.x,
                    top: startXY.y
                };
                this.mousePos = {x: event.clientX, y: event.clientY};

                this.unlistenMouseMove = this.html.attachMouseMove(document, (event: MouseEvent) => {
                    if (!isMouseDown) {
                        this.commitObjectChanges();
                        return;
                    }
                    this.mousePos = {x: event.clientX, y: event.clientY};
                    this.moveObject();
                });

                this.unlistenMouseUp = this.html.attachMouseUp(document, () => {
                    isMouseDown = false;
                    if (this.unlistenMouseMove) {
                        this.unlistenMouseMove();
                        this.unlistenMouseMove = undefined;
                    }
                    if (this.unlistenMouseUp) {
                        this.unlistenMouseUp();
                        this.unlistenMouseUp = undefined;
                    }
                    this.commitObjectChanges();
                });
            });

            // // by arrows, we always set final position each time
            this.unlistenArrowUp = this.html.attachArrowUpPress(document, () => {
                console.log('UP');
                this.resetMouse(this.calculateDragStartingPoint());
                this.moveObject(0, -1);
                this.commitObjectChanges();
            });
            this.unlistenArrowDown = this.html.attachArrowDownPress(document, () => {
                console.log('DOWN');
                this.resetMouse(this.calculateDragStartingPoint());
                this.moveObject(0, 1);
                this.commitObjectChanges();
            });
            this.unlistenArrowLeft = this.html.attachArrowLeftPress(document, () => {
                console.log('LEFT');
                this.resetMouse(this.calculateDragStartingPoint());
                this.moveObject(-1, 0);
                this.commitObjectChanges();
            });
            this.unlistenArrowRight = this.html.attachArrowRightPress(document, () => {
                console.log('RIGHT');
                this.resetMouse(this.calculateDragStartingPoint());
                this.moveObject(1, 0);
                this.commitObjectChanges();
            });
        });
    }

    /**
     * Calculates a correct starting point of dragging, that corresponds to the left upper
     * corner of object. Origin is very important here, the method correctly resolves
     * starting point for {@see PlacementOrigin.Center}.
     * @private
     */
    private calculateDragStartingPoint(): UiPoint {
        if (this.currentObject!.origin === PlacementOrigin.Center) {
            this.currentObject!.origin = PlacementOrigin.LeftTop;
            this.currentObjectChanged = true;

            // calculate current left/top position of object that is centered in parent, e.g. in desktop
            const parentW = this.currentObjectParentElement!.clientWidth;
            const parentH = this.currentObjectParentElement!.clientHeight;
            const objectStyleWidth = parseInt(this.currentObjectElement!.style.width);
            const objectStyleHeight = parseInt(this.currentObjectElement!.style.height);
            const x = parentW / 2.0;
            const y = parentH / 2.0;
            const marginLeft = -objectStyleWidth / 2.0;
            const marginTop = -objectStyleHeight / 2.0;
            return new UiPoint(x + marginLeft, y + marginTop);
        }
        return new UiPoint(this.currentObject!.x, this.currentObject!.y);
    }

    private onObjectsDeselected(): void {
        // reset basic settings for object
        this.currentObjectParentElement = undefined;
        this.currentObjectElement = undefined;
        this.currentObject = undefined;
        this.currentObjectChanged = false;

        if (this.unlistenMouseDown) {
            this.unlistenMouseDown();
            this.unlistenMouseDown = undefined;
        }
        if (this.unlistenArrowUp) {
            this.unlistenArrowUp();
            this.unlistenArrowUp = undefined;
        }
        if (this.unlistenArrowDown) {
            this.unlistenArrowDown();
            this.unlistenArrowDown = undefined;
        }
        if (this.unlistenArrowLeft) {
            this.unlistenArrowLeft();
            this.unlistenArrowLeft = undefined;
        }
        if (this.unlistenArrowRight) {
            this.unlistenArrowRight();
            this.unlistenArrowRight = undefined;
        }
    }

    /**
     * Saves all changes made to object during manipulation
     * @private
     */
    private commitObjectChanges(): void {
        this.html.updateVisualObject(this.currentObject!);
        this.resetMouse();

        if (this.currentObjectChanged) {
            this.ngZone.run(() => {
                this.store.dispatch(new EditorActions.ChangeObjectData(this.currentObject!));
            });
        }
    }

    /**
     * Set default values for mouse dragging start position and mouse current position.
     * @private
     */
    private resetMouse(startPoint?: UiPoint): void {
        const elementWidth = parseInt(this.currentObjectElement!.style.width);
        const elementHeight = parseInt(this.currentObjectElement!.style.height);
        startPoint = startPoint || new UiPoint(this.currentObject!.x, this.currentObject!.y);
        this.mouseStartDrag = {
            x: elementWidth / 2.0,
            y: elementHeight / 2.0,
            left: startPoint.x,
            top: startPoint.y
        };
        this.mousePos = {
            x: elementWidth / 2.0, y: elementHeight / 2.0
        };
    }

    /**
     * Updates object position based on given delta movement.
     * @param dx Movement delta on X axis.
     * @param dy Movement delta on Y axis.
     * @private
     */
    private moveObject(dx: number = 0, dy: number = 0): void {

        const parent = {
            x: this.currentObjectParentElement!.offsetLeft,
            y: this.currentObjectParentElement!.offsetTop,
            w: this.currentObjectParentElement!.clientWidth,
            h: this.currentObjectParentElement!.clientHeight,
            rect: this.currentObjectParentElement!.getBoundingClientRect()
        };
        let x: number;
        let y: number;

        if (dx === 0) {
            dx = this.mousePos.x - this.mouseStartDrag.x;
            x = this.mouseStartDrag.left + dx; // use mouse positioning
        }
        else {
            x = this.currentObjectElement!.offsetLeft + dx; // use relative positioning
        }

        if (dy === 0) {
            dy = this.mousePos.y - this.mouseStartDrag.y;
            y = this.mouseStartDrag.top + dy; // use mouse positioning
        }
        else {
            y = this.currentObjectElement!.offsetTop + dy; // use relative positioning
        }

        // watch limits
        const elementWidth = parseInt(this.currentObjectElement!.style.width);
        const elementHeight = parseInt(this.currentObjectElement!.style.height);
        const desktopRect = this.html.getDesktopBoundingRect();
        x = Math.max(x, desktopRect.x - parent.rect.x + MOVEMENT_EDGE_MARGIN);
        y = Math.max(y, desktopRect.y - parent.rect.y + MOVEMENT_EDGE_MARGIN);
        x = Math.min(x, desktopRect.width - (parent.rect.x - desktopRect.x) - elementWidth - MOVEMENT_EDGE_MARGIN);
        y = Math.min(y, desktopRect.height - (parent.rect.y - desktopRect.y) - elementHeight - MOVEMENT_EDGE_MARGIN);

        this.currentObject!.x = x;
        this.currentObject!.y = y;
        this.currentObjectChanged = true;

        // For movement, we use left/top offsets only in pixels
        const vp = new VisualPositioning(x, y);
        this.html.updateVisualObjectPositioning(vp, this.currentObjectElement!);
        this.bus.publish(BUS_EVENT_OBJECT_MOVE, this.currentObject!);
    }
}
