export namespace HintBoxActions {
    export class Expanded {
        static readonly type = '[HintBox] Expanded';
    }

    export class Collapsed {
        static readonly type = '[HintBox] Collapsed';
    }

    export class Cancel {
        static readonly type = '[HintBox] Cancel pressed';
    }
}
