import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable, Subject} from 'rxjs';
import {ImageObjectSettingsModal} from '../components/editor-objects/image-object-settings-modal/image-object-settings.modal';
import {UIEditorObject} from '../models/ui-editor-object.model';
import {HtmlHelperService} from './html-helper.service';

@Injectable({
    providedIn: 'root'
})
export class ModalsService {

    constructor(private modalService: NgbModal, private html: HtmlHelperService) {
    }

    public showImageObjectSettings(object: UIEditorObject): Observable<UIEditorObject> {
        const subject = new Subject<UIEditorObject>();
        const modal = this.modalService.open(
            ImageObjectSettingsModal,
            {
                centered: true,
                windowClass: 'image-settings-modal',
                size: 'lg'
            }
        );
        const component = (modal.componentInstance as ImageObjectSettingsModal);
        component.editorObject = Object.assign({}, object);

        // cancelled
        modal.dismissed.subscribe(() => {
            subject.next(component.editorObject);
            subject.complete();
        });

        // saved
        modal.closed.subscribe(() => {
            subject.next(component.editorObject);
            subject.complete();
        });

        return subject.asObservable();
    }

    /**
     * Make currently visible modal temporary invisible but still active.
     */
    public hideModal(): void {
        this.html.hideObject('.modal-backdrop');
        this.html.hideObject('.modal');
    }

    /**
     * Reveal temporary invisible modal.
     */
    public revealModal(): void {
        this.html.revealObject('.modal-backdrop');
        this.html.revealObject('.modal');
    }

    // public showSuccess(title: string, message: string): Observable<ImageObjectModel> {
    //     const subject = new Subject<ImageObjectModel>();
    //     const modal = this.modalService.open(
    //         ImageObjectSettingsModal,
    //         {
    //             centered: true,
    //             windowClass: 'image-settings-modal',
    //             size: 'lg'
    //         }
    //     );
    //     const component = (modal.componentInstance as ImageObjectSettingsModal);
    //     component.editorObject = Object.assign({}, object);
    //
    //     // cancelled
    //     modal.dismissed.subscribe(() => {
    //         subject.next(component.editorObject);
    //         subject.complete();
    //     });
    //     // saved
    //     modal.closed.subscribe(() => {
    //         subject.next(component.editorObject);
    //         subject.complete();
    //     });
    //
    //     return subject.asObservable();
    // }
}
