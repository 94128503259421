import {Component, Input, OnInit} from '@angular/core';
import {ClipboardService} from 'ngx-clipboard';
import {WindowService} from 'common-lib';

/**
 * Component representing simple value which can be copied to clipboard
 * or treated as URL.
 */
@Component({
    selector: 'ip-object-value-setting',
    templateUrl: './object-value-setting.component.html',
    styleUrls: ['./object-value-setting.component.scss']
})
export class ObjectValueSettingComponent implements OnInit {

    @Input()
    public value: string = '';
    /**
     * A label displayed on the left side of the value.
     */
    @Input()
    public label: string | undefined;

    @Input()
    public canCopyToClipboard: boolean = true;

    @Input()
    public isLink: boolean = true;

    constructor(private clipboard: ClipboardService, private windowService: WindowService) {

    }

    public ngOnInit(): void {

    }

    public onCopyClick(): void {
        this.clipboard.copyFromContent(this.value);
    }

    public onLinkClick(): void {
        this.windowService.open(this.value);
    }
}
