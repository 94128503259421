<div #host class="handle anchor-{{position}} color-{{color.toLowerCase()}}"
     [class.vertical]="isVertical"
     [class.locked]="locked"
     [class.inactive]="!active"
     [style.display]="visible ? 'block' : 'none'"
>
    <div class="line"></div>
    <div class="dotted-line"></div>
    <div class="icon-box" (click)="onIconClicked($event)" (contextmenu)="onIconRightClicked($event)">
        <i class="bi bi-link"></i>
    </div>
</div>
