import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable, Subscription} from 'rxjs';
import {UITrainingStep} from '../../models/ui-training-step.model';
import {EditorActions} from '../../store/editor.actions';
import {EditorSelectors} from '../../store/editor.selectors';
import {ToolbarMenuActions} from '../../store/toolbar-menu.actions';
import {EditorStateModel} from '../../store/models/editor-state.model';
import {ToolbarStepsActions} from '../../store/toolbar-steps.actions';
import {MenuPosition} from '../menu/menu.component';
import {StepTypes} from '../navbar-step/navbar-step.component';
import {ToolbarMenuItemType, ToolbarButtonItemType, ToolbarStepsMenuItemType} from './editor-toolbar.models';

@Component({
    selector: 'ip-editor-toolbar',
    templateUrl: './editor-toolbar.component.html',
    styleUrls: ['./editor-toolbar.component.scss']
})
export class EditorToolbarComponent implements OnInit, OnDestroy {

    @Select(EditorSelectors.getSteps) public editorSteps$!: Observable<UITrainingStep[]>;
    public stepTypes = StepTypes;
    public menuPosition = MenuPosition;
    public showLeftMenu = false;
    public showRightMenu = false;
    public isExpanded = true;
    public stepsParams = {
        selected: false,
        firstSelected: false,
        lastSelected: false,
        count: 0
    };
    public ToolbarItemType = ToolbarButtonItemType;
    public MenuItemType = ToolbarMenuItemType;
    public StepsMenuItemType = ToolbarStepsMenuItemType;

    @Input() public disabled: boolean = false;
    @Output() public onExpandedChanged: EventEmitter<boolean> = new EventEmitter<boolean>();


    menuOverlayClicked(): void {
        this.showLeftMenu = false;
        this.showRightMenu = false;
    }

    private subscription = new Subscription();

    constructor(private store: Store) {
    }

    addStep() {
        this.store.dispatch(new ToolbarStepsActions.AddStep());
    }

    addStepAtIndex(index: number) {
        this.store.dispatch(new ToolbarStepsActions.AddStepAtIndex(index));
    }

    public onStepMenuClicked(item: ToolbarStepsMenuItemType): void {
        switch (item) {
            case ToolbarStepsMenuItemType.MoveToBegin:
                this.store.dispatch(new ToolbarStepsActions.MoveStepToFirstPosition());
                break;
            case ToolbarStepsMenuItemType.MoveToEnd:
                this.store.dispatch(new ToolbarStepsActions.MoveStepToLastPosition());
                break;
            case ToolbarStepsMenuItemType.Duplicate:
                this.store.dispatch(new ToolbarStepsActions.DuplicateSelectedStep());
                break;
            case ToolbarStepsMenuItemType.Remove:
                this.store.dispatch(new ToolbarStepsActions.RemoveSelectedStep());
                break;
            case ToolbarStepsMenuItemType.RemoveAll:
                this.store.dispatch(new ToolbarStepsActions.RemoveAllSteps());
                break;
        }
    }

    public onToolbarClicked(item: ToolbarButtonItemType): void {
        switch (item) {
            // case ToolbarButtonItemType.AddInteractiveObject:
            //     this.store.dispatch(new EditorActions.AddStaticImage());
            //     break;
            case ToolbarButtonItemType.AddStaticImage:
                this.store.dispatch(new EditorActions.AddStaticImage());
                break;
            // case ToolbarButtonItemType.AddTooltip:
            //     this.store.dispatch(new ToolbarButtonsActions.AddTooltip());
            //     break;
        }
    }

    public onMenuClick(item: ToolbarMenuItemType): void {
        switch (item) {
            // case ToolbarMenuItemType.NewTraining:
            //     this.store.dispatch(new ToolbarMenuActions.NewTraining());
            //     break;
            // case ToolbarMenuItemType.SaveTraining:
            //     this.store.dispatch(new ToolbarMenuActions.SaveTraining());
            //     break;
            // case ToolbarMenuItemType.TrainingProperties:
            //     this.store.dispatch(new ToolbarMenuActions.TrainingProperties());
            //     break;
            case ToolbarMenuItemType.ExitEditor:
                this.store.dispatch(new ToolbarMenuActions.ExitEditor());
                break;
        }
    }

    public toggleCollapse(): void {
        this.isExpanded = !this.isExpanded;
        this.onExpandedChanged.emit(this.isExpanded);
    }

    public ngOnInit(): void {
        this.subscription.add(
            this.store
                .select((state) => state.editor)
                .subscribe((editor: EditorStateModel) => {
                    this.stepsParams = {
                        count: editor.training.steps.length,
                        selected: editor.selectedStepIndex !== -1,
                        firstSelected: editor.selectedStepIndex === 0,
                        lastSelected: editor.selectedStepIndex === editor.training.steps.length - 1
                    };
                })
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
