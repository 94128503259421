<div class="hint-box text-center shadow" [class.expanded]="isExpanded"
     (click)="onContainerClick($event)">
    <div class="content d-flex flex-column h-100 justify-content-center">
        <h4 class="title">{{title}}</h4>
        <div class="message mt-4">{{message}}</div>
        <div class="buttons mt-4">
            <button class="ip-btn-framed ip-btn-white" (click)="cancelButtonClick($event)">Anuluj (ESC)</button>
        </div>
        <button class="close-btn ip-btn-icon ip-btn-white" (click)="onCloseClick($event)">
            <i class="bi bi-x-lg"></i>
        </button>
    </div>
    <div class="info h-100 d-flex justify-content-center">
        <i class="bi bi-info-lg align-self-center"></i>
    </div>
</div>
