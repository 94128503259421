import {Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngxs/store';
import {HintBoxActions} from '../../store/hint-box.actions';

@Component({
    selector: 'ip-hint-box',
    templateUrl: './hint-box.component.html',
    styleUrls: ['./hint-box.component.scss']
})
export class HintBoxComponent implements OnInit {

    @Input() public isExpanded: boolean = false;
    @Input() public title: string | undefined;
    @Input() public message: string | undefined;

    constructor(private store: Store) {
    }

    public ngOnInit(): void {
    }

    public onCloseClick(event: MouseEvent): void {
        event.stopPropagation();
        this.isExpanded = false;
        this.store.dispatch(new HintBoxActions.Collapsed());
    }

    public onContainerClick(event: MouseEvent): void {
        event.stopPropagation();
        this.isExpanded = true;
        this.store.dispatch(new HintBoxActions.Expanded());
    }

    public cancelButtonClick(event: MouseEvent): void {
        event.stopPropagation();
        this.store.dispatch(new HintBoxActions.Cancel());
    }
}
