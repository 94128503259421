import {Component, OnInit} from '@angular/core';
import {Subscription, timer} from 'rxjs';
import {WindowsDateTime} from '../../models/date-time.model';
import {UtilsService} from '../../services/utils.service';

@Component({
    selector: 'ip-windows-clock',
    templateUrl: './windows-clock.component.html',
    styleUrls: ['./windows-clock.component.scss']
})
export class WindowsClockComponent implements OnInit {
    constructor() {
    }

    private subscribtions = new Subscription();

    public dateTime: WindowsDateTime = {time: '', date: ''};

    ngOnInit(): void {
        this.dateTime = UtilsService.getWindowsDateTime();
        this.subscribtions.add(
            timer(59000).subscribe(() => {
                this.dateTime = UtilsService.getWindowsDateTime();
            })
        );
    }
}
