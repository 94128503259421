import {Component, Input} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {EditorSelectors} from '../../store/editor.selectors';
import {ToolbarStepsActions} from '../../store/toolbar-steps.actions';

export enum StepTypes {Start = 'start', Position = 'position'}

@Component({
    selector: 'ip-navbar-step',
    templateUrl: './navbar-step.component.html',
    styleUrls: ['./navbar-step.component.scss']
})
export class NavbarStepComponent {

    @Input() stepType: StepTypes = StepTypes.Position;
    @Input() index: number = -1;

    @Select(EditorSelectors.getSelectedStepIndex) selectedStepIndex$!: Observable<number>;

    constructor(private store: Store) {
    }

    onClick(): void {
        this.store.dispatch(new ToolbarStepsActions.SelectStep(this.index));
    }

}
