import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginPageComponent} from './login-page/login-page.component';


export const routes: Routes = [
    {path: 'login', component: LoginPageComponent},
    {path: 'editor', loadChildren: () => import('./editor/editor.module').then(m => m.EditorModule)}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
