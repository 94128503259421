export interface TrainingTestResultsModel {
    /**
     * The date of last test execution.
     *  ISO String date format.
     */
    lastTestRunDate: string;
    /**
     * Did user pass the test for course?
     */
    isPassed: boolean;
    /**
     * How many errors user did.
     */
    errorsCount: number;
}
