import {Injectable} from '@angular/core';
import {EditorObjectModel, ImageObjectModel, InteractiveObjectModel, TrainingModel} from 'common-lib';
import * as _ from 'lodash';
import {TrainingStepModel} from '../../../../../common-lib/src/models';
import {DEFAULT_OBJECT_COLOR} from '../constants';
import {UiEditorObjectType} from '../models/ui-editor-object-type.enum';
import {UIEditorObject} from '../models/ui-editor-object.model';
import {UITrainingStep} from '../models/ui-training-step.model';
import {UITraining} from '../models/ui-training.model';

@Injectable({
    providedIn: 'root'
})
export class TrainingMapperService {

    public mapToUI(training: TrainingModel): UITraining {
        const steps = training.steps?.map(step => {
            const allObjects = this.hierarchicalToFlat(step);
            const objectsById: { [id: string]: number } = {};
            const objects = allObjects.map((object, index) => {
                objectsById[object.id] = index;
                // find all children' IDs
                const children: string[] = [];
                allObjects.forEach(o => {
                    if (o.parentId === object.id) {
                        children.push(o.id);
                    }
                });
                return this.editorObjectModelToUI(object, children);
            });
            return <UITrainingStep> {
                id: step.id,
                objects,
                objectsById
            };
        });
        return <UITraining> {
            id: training.id,
            name: training.name,
            os: training.os,
            osVersion: training.osVersion,
            isArchived: training.isArchived,
            difficultLevel: training.difficultLevel,
            description: training.description,
            createdDate: new Date(training.createdDate),
            lastUpdateDate: training.lastUpdateDate ? new Date(training.lastUpdateDate) : undefined,
            archivedDate: training.archivedDate ? new Date(training.archivedDate) : undefined,
            pricing: _.cloneDeep(training.pricing),
            steps: steps || []
        };
    }

    private editorObjectModelToUI(object: EditorObjectModel, children: string[]): UIEditorObject {
        return <UIEditorObject> {
            id: object.id,
            name: object.name,
            parentId: object.parentId,
            color: object.color || DEFAULT_OBJECT_COLOR,
            type: (<ImageObjectModel> object).imageId ? UiEditorObjectType.Image : UiEditorObjectType.Interactive,
            x: object.x,
            y: object.y,
            width: object.width,
            height: object.height,
            origin: object.origin,
            imageId: (<ImageObjectModel> object).imageId,
            imageType: (<ImageObjectModel> object).imageType,
            tooltip: (<InteractiveObjectModel> object).tooltip,
            clickActions: (<InteractiveObjectModel> object).clickActions,
            mouseInActions: (<InteractiveObjectModel> object).mouseInActions,
            mouseOutActions: (<InteractiveObjectModel> object).mouseOutActions,
            visible: (<InteractiveObjectModel> object).visible,
            children
        };
    }

    private hierarchicalToFlat(step: TrainingStepModel): EditorObjectModel[] {
        let result: EditorObjectModel[] = [];
        if (step.imageObjects) {
            step.imageObjects.forEach(o => {
                result = [
                    ...result,
                    <any> o,
                    ...this.flatten(<any> o)
                ];
            });
        }
        if (step.interactiveObjects) {
            step.interactiveObjects.forEach(o => {

                result = [
                    ...result,
                    <any> o,
                    ...this.flatten(o)
                ];
            });
        }
        return result;
    }

    private flatten(objectModel: EditorObjectModel): EditorObjectModel[] {
        const object = objectModel as ImageObjectModel;
        let result: EditorObjectModel[] = [];

        if (object.staticChildren) {
            object.staticChildren.forEach(o => {
                result = [
                    ...result,
                    <any> o,
                    ...this.flatten(<any> o)
                ];
            });
        }
        if (object.interactiveChildren) {
            object.interactiveChildren.forEach(o => {
                result = [
                    ...result,
                    <any> o,
                    ...this.flatten(<any> o)
                ];
            });
        }

        return result;
    }
}
