export class VisualPositioning {
    constructor(
        public left?: number,
        public top?: number,
        public right?: number,
        public bottom?: number,
        public marginLeft?: number,
        public marginTop?: number,
        public marginRight?: number,
        public marginBottom?: number,
        public posUnit: 'px' | '%' = 'px'
    ) {
    }
}
