import {UserRole} from './user-role.enum';

export interface UserSimpleModel {
    id?: string;
    name: string;
    email: string;
    login: string;
    lastLoginDate?: string;
    creationDate: string;
    lastUpdateDate?: string;
    birthDate?: string;
    age?: number;
    avatar?: string;
    roles?: UserRole[];
    isVerified: boolean;
    isBlocked: boolean;
}
