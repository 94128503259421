import {MediaMimeType} from '../media/media-mime-type.enum';
import {EditorObjectModel} from './editor-object.model';
import {InteractiveObjectModel} from './interactive-object.model';

/**
 * A step object that can be hovered or clicked/tapped by the user.
 */
export interface ImageObjectModel extends EditorObjectModel {
    /**
     * ID of the uploaded image.
     */
    imageId: string;
    /**
     * Type of the uploaded image.
     */
    imageType: MediaMimeType;
    /**
     * A set of static child objects which positions depend on this object position. The current object is used as
     * a parent container for children. If parent is moved, children are either.
     */
    staticChildren?: ImageObjectModel[];
    /**
     * A set of interactive child objects which positions depend on this object position. The current object is used as
     * a parent container for children. If parent is moved or adjusted, children are either.
     */
    interactiveChildren?: InteractiveObjectModel[];
}
