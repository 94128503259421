import {
    ImageObjectModel,
    MediaMimeType,
    ObjectColor,
    PlacementOrigin,
    TrainingStepModel,
    OperatingSystem,
    PricingModel,
    TrainingModel
} from 'common-lib';


const iconImageObject = <ImageObjectModel> {
    x: 20,
    y: 130,
    origin: PlacementOrigin.LeftTop,
    color: ObjectColor.Red,
    width: 56,
    height: 83,
    id: 'Windows 10 Folder Icon',
    name: 'Windows10-FolderIcon.png',
    imageId: '62862a08c725f317fab44285',
    imageType: MediaMimeType.PNG,
    parentId: undefined,
    staticChildren: undefined,
    interactiveChildren: undefined
};

const explorerWindow = <ImageObjectModel> {
    x: 0,
    y: 0,
    origin: PlacementOrigin.Center,
    width: 892,
    height: 615,
    id: 'Obrazy-Window',
    name: 'ExampleParent',
    imageId: '62862a06c725f317fab44279',
    imageType: MediaMimeType.PNG,
    parentId: undefined, // laying on desktop
    staticChildren: undefined,
    interactiveChildren: undefined
};


const contextMenuImageObject = <ImageObjectModel> {
    x: 700,
    y: 340,
    origin: PlacementOrigin.LeftTop,
    width: 292,
    height: 375,
    id: 'ContextMenu',
    name: 'ContextMenu image',
    imageId: '62862a08c725f317fab44282',
    imageType: MediaMimeType.PNG,
    color: ObjectColor.Red,
    parentId: explorerWindow.id,
    staticChildren: undefined,
    interactiveChildren: undefined
};

const subMenuImageObject = <ImageObjectModel> {
    x: 285,
    y: -6,
    origin: PlacementOrigin.LeftTop,
    width: 209,
    height: 186,
    id: 'SubMenu',
    name: 'SubMenu image',
    imageId: '62862a08c725f317fab44283',
    imageType: MediaMimeType.PNG,
    color: ObjectColor.Red,
    parentId: contextMenuImageObject.id,
    staticChildren: undefined,
    interactiveChildren: undefined
};

const exampleStep0 = <TrainingStepModel> {
    id: 'step-0-id',
    imageObjects: [
        {...iconImageObject}
    ]
};

const exampleStep1 = <TrainingStepModel> {
    id: 'step-1-id',
    imageObjects: [
        {...iconImageObject},
        {...explorerWindow}
    ]
};

const exampleStep2 = <TrainingStepModel> {
    id: 'step-2-id',
    imageObjects: [
        {...iconImageObject},
        {
            ...explorerWindow,
            staticChildren: [
                {...contextMenuImageObject}
            ]
        }
    ]
};

const exampleStep3 = <TrainingStepModel> {
    id: 'step-3-id',
    imageObjects: [
        {...iconImageObject},
        {
            ...explorerWindow,
            staticChildren: [
                {
                    ...contextMenuImageObject,
                    staticChildren: [
                        {...subMenuImageObject}
                    ]
                }
            ]
        }
    ]
};

export const exampleTraining = <TrainingModel> {
    id: 'TrainingID',
    name: 'A test training',
    createdDate: new Date().toISOString(),
    description: 'An example training with few steps.',
    os: OperatingSystem.Windows,
    osVersion: '10',
    difficultLevel: 1,
    isArchived: false,
    pricing: <PricingModel> {
        basePrice: 4.99,
        currency: 'PLN',
        hasPromotion: false,
        price: 4.99
    },
    steps: [
        exampleStep0,
        exampleStep1,
        exampleStep2,
        exampleStep3
    ]
};
