export enum ToolbarButtonItemType {
    AddStaticImage = 'AddStaticImage',
    AddTooltip = 'AddTooltip',
    AddInteractiveObject = 'AddInteractiveObject'
}

export enum ToolbarMenuItemType {
    NewTraining = 'NewTraining',
    SaveTraining = 'SaveTraining',
    TrainingProperties = 'TrainingProperties',
    ExitEditor = 'ExitEditor'
}

export enum ToolbarStepsMenuItemType {
    MoveToEnd = 'MoveStepToEnd',
    MoveToBegin = 'MoveToBegin',
    Duplicate = 'Duplicate',
    Remove = 'Remove',
    RemoveAll = 'RemoveAll'
}
