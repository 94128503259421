import {EnumDictionary} from 'common-lib';
import {ObjectColor} from '../../../../common-lib/src/models';
import {HandlePosition} from './models/handle-position.enum';
import {UiEditorObjectType} from './models/ui-editor-object-type.enum';

/**
 * The minimum margin between edge of screen (Desktop) and any object edge.
 * @private
 */
export const MOVEMENT_EDGE_MARGIN: number = 20;

/**
 * The width or height of the handle, used to correct positioning.
 */
export const ANCHOR_HANDLE_SIZE: number = 20;

export const SELECTED_OBJECT_BORDER_WIDTH: number = 1; // in pixels

/**
 * The string displayed for ObjectSelectorSettingComponent when no object was defined.
 */
export const EMPTY_OBJECT_SELECTION: string = '. . .';

export const DEFAULT_OBJECT_COLOR: ObjectColor = ObjectColor.Yellow;

export interface ObjectColorDataItem {
    cssColorName: string;
    color: ObjectColor;
}

export const OBJECT_COLORS_MAP: { [key in ObjectColor]: string } = {
    [ObjectColor.Yellow]: 'special2',
    [ObjectColor.Green]: 'success',
    [ObjectColor.Red]: 'danger',
    [ObjectColor.Black]: 'black',
    [ObjectColor.Blue]: 'secondary'
};

export const OBJECT_TAGS_MAP: { [key in UiEditorObjectType]: string } = {
    [UiEditorObjectType.Image]: 'ip-image-object',
    [UiEditorObjectType.Interactive]: 'ip-interactive-object'
};

export const OBJECT_COLORS_DATA: ObjectColorDataItem[] = [
    {cssColorName: OBJECT_COLORS_MAP[ObjectColor.Yellow], color: ObjectColor.Yellow},
    {cssColorName: OBJECT_COLORS_MAP[ObjectColor.Green], color: ObjectColor.Green},
    {cssColorName: OBJECT_COLORS_MAP[ObjectColor.Red], color: ObjectColor.Red},
    {cssColorName: OBJECT_COLORS_MAP[ObjectColor.Black], color: ObjectColor.Black},
    {cssColorName: OBJECT_COLORS_MAP[ObjectColor.Blue], color: ObjectColor.Blue}
];

export const BUS_EVENT_OBJECT_MOVE = 'MoveEditorObjectBusEvent';

export const BUS_EVENT_OBJECT_SELECTION_INITIALIZED = 'EditorObjectSelectionInitializedBusEvent';

export const BUS_EVENT_OBJECT_HANDLE_UPDATE_MAP: EnumDictionary<HandlePosition, string> = {
    [HandlePosition.Left]: 'EditorObjectLeftHandleUpdateBusEvent',
    [HandlePosition.Top]: 'EditorObjectTopHandleUpdateBusEvent',
    [HandlePosition.Right]: 'EditorObjectRightHandleUpdateBusEvent',
    [HandlePosition.Bottom]: 'EditorObjectBottomHandleUpdateBusEvent'
};


