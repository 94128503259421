import {TooltipStyle} from './tooltip-style.enum';

export interface TooltipModel {
    /**
     * Tooltip HTML text with simple formatting like <b> or <i>.
     */
    text: string;
    /**
     * Optional icon name to display.
     */
    icon?: string;
    /**
     * Style of tooltip - colors.
     */
    style: TooltipStyle;
}
