import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Actions, ofActionDispatched, Store} from '@ngxs/store';
import {ObjectColor} from 'common-lib';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {BUS_EVENT_OBJECT_HANDLE_UPDATE_MAP} from '../../../constants';
import {HandlePosition} from '../../../models/handle-position.enum';
import {EventBusService} from '../../../services/event-bus.service';
import {HtmlHelperService} from '../../../services/html-helper.service';
import {EditorActions} from '../../../store/editor.actions';
import {HandleSettings} from '../handles-settings.interface';

@Component({
    selector: 'ip-anchor-handle',
    templateUrl: './anchor-handle.component.html',
    styleUrls: ['./anchor-handle.component.scss']
})
export class AnchorHandleComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject();
    private eventBusSubscription!: string;

    @ViewChild('host', {static: true}) public hostRef!: ElementRef<HTMLElement>;
    /**
     * Position of the handle relative to the object.
     */
    @Input() public position: HandlePosition = HandlePosition.Left;
    /**
     * Denotes if the handle is currently active or not (semitransparent).
     */
    public active: boolean = false;
    /**
     * Denotes if the handle is currently locked or not (not clickable).
     */
    public locked: boolean = false;
    /**
     * Denotes if the handle is currently visible or not.
     */
    public visible: boolean = false;
    /**
     * Defines a handle theme color.
     */
    public color: ObjectColor = ObjectColor.Yellow;

    @Output() initialized = new EventEmitter();

    public get isVertical(): boolean {
        return this.position === 'top' || this.position === 'bottom';
    }

    constructor(
        private store: Store,
        private actions: Actions,
        private html: HtmlHelperService,
        private bus: EventBusService
    ) {
        this.actions
            .pipe(
                takeUntil(this.unsubscribe$),
                ofActionDispatched(EditorActions.UpdateObjectHandlesEvent)
            )
            .subscribe((ctx: EditorActions.UpdateObjectHandlesEvent) => {
                this.onUpdateHandle(ctx.settings[this.position]);
            });
    }

    public ngOnInit(): void {
        this.eventBusSubscription = this.bus.subscribe(
            BUS_EVENT_OBJECT_HANDLE_UPDATE_MAP[this.position],
            (data: HandleSettings) => {
                this.onUpdateHandle(data);
            }
        );
        this.initialized.emit();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.bus.unsubscribe(this.eventBusSubscription);
    }

    public onIconClicked(event: MouseEvent): void {
        event.stopPropagation();
        this.store.dispatch(new EditorActions.ObjectHandleLeftClick(this.position));
    }

    public onIconRightClicked(event: MouseEvent): void {
        event.stopPropagation();
        event.preventDefault();
        this.store.dispatch(new EditorActions.ObjectHandleRightClick(this.position));
    }

    private onUpdateHandle(settings: HandleSettings): void {
        this.color = settings.color;
        this.locked = settings.isLocked;
        this.active = settings.isActive;
        this.visible = settings.isVisible;
        this.html.updateVisualObjectPositioning(settings.positioning, this.hostRef.nativeElement);
    }
}
