import {PricingPromotionModel} from './pricing-promotion.model';

export interface PricingModel {
    /**
     * A final price after all promotions.
     */
    price: number;
    /**
     * A base price before any promotions and discounts.
     */
    basePrice: number;
    /**
     * A name of currency used for all prices - a translation key, e.g. "CURRENCY.PLN".
     * Currency is based on actual country.
     */
    currency: string;
    /**
     * Informs directly if actual price contains any promotions applied.
     */
    hasPromotion: boolean;
    /**
     * A list of promotions applied to current basePrice.
     * The order is very important.
     */
    promotions?: PricingPromotionModel[];
}




