<div class="host">{{label}}
    <div class="selected-color bg-{{selectedCssColorName}}" (click)="toggleMenu()">
        <ip-popup [id]="popupId">
            <div class="menu">
                <div *ngFor="let item of colors"
                     class="menu-item"
                     [class.selected]="item.color === selectedColor"
                     (click)="onMenuItemClick(item.color); $event.stopPropagation();">
                    <span class="color-item bg-{{item.cssColorName}}"></span>
                </div>
            </div>
        </ip-popup>
    </div>
</div>
