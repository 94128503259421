export interface ErrorResponse {
    /**
     * An error message returned by API.
     */
    error?: string;
    /**
     * An error status code.
     */
    code?: number;
    /**
     * Error details.
     */
    details?: string;
    /**
     * An optional stack trace.
     */
    stack?: string | string[];
}
