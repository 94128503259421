import {Injectable} from '@angular/core';
import * as uuid from 'uuid';

export type EventBusHandler = (data: any) => void;
type EventBusHandlersDictionary = { [id: string]: EventBusHandler };

/**
 * Service provideing direct fast event bus between services that need
 * very fast direct event connection, e.g. to response for mouse
 * movement or mouse fast changes.
 */
@Injectable({
    providedIn: 'root'
})
export class EventBusService {
    /**
     * Internal register of event handlers.
     * @private
     */
    private bus: { [key: string]: EventBusHandlersDictionary } = {};

    /**
     * Publishes new event in event bus for given key.
     * @param key A unique key identifying the bus.
     * @param data The data to be passed with the event.
     */
    public publish(key: string, data: any): void {
        if (this.bus[key]) {
            for (const handlerId in this.bus[key]) {
                const handlersDict = this.bus[key];
                const handler = handlersDict[handlerId];
                handler(data);
            }
        }
    }

    /**
     * Allows to subscribe one event handler for each key.
     * @param key A unique key identifying the bus.
     * @param handler A function to be called when event will be dispatched on bus key.
     */
    public subscribe(key: string, handler: EventBusHandler): string {
        if (!this.bus[key]) {
            this.bus[key] = {};
        }
        const handlerId = uuid.v4();
        this.bus[key][handlerId] = handler;
        return `${key}|${handlerId}`;
    }

    /**
     * Removes the handler for given key.
     * @param id The id of subscription returned after subscription.
     */
    public unsubscribe(id: string): void {
        const [key, handlerId] = id.split('|');
        const handlers = this.bus[key];
        if (handlers && handlers[handlerId]) {
            delete handlers[handlerId];
        }
    }
}
