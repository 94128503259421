import {Injectable, NgZone} from '@angular/core';
import {Actions, ofActionDispatched, Store} from '@ngxs/store';
import {EnumDictionary, PlacementOrigin} from 'common-lib';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {HandleSettings} from '../components/editor-objects/handles-settings.interface';
import {ANCHOR_HANDLE_SIZE, BUS_EVENT_OBJECT_HANDLE_UPDATE_MAP, BUS_EVENT_OBJECT_MOVE, MOVEMENT_EDGE_MARGIN} from '../constants';
import {HandlePosition} from '../models/handle-position.enum';
import {UIEditorObject} from '../models/ui-editor-object.model';
import {UiPoint} from '../models/ui-point.model';
import {VisualPositioning} from '../models/visual-positioning';
import {EditorActions} from '../store/editor.actions';
import {EventBusService} from './event-bus.service';
import {HtmlHelperService} from './html-helper.service';

@Injectable({
    providedIn: 'root'
})
export class HandlesService {
    private unsubscribe$ = new Subject();
    private objectMoveBusSubscription!: string;
    /**
     * Represent currently selected object data model.
     * @private
     */
    private currentObject?: UIEditorObject;
    /**
     * Represents currently selected object parent HTML native element.
     * @private
     */
    private currentObjectParentElement?: HTMLElement;
    /**
     * Represents currently selected object HTML native element.
     * @private
     */
    private currentObjectElement?: HTMLElement;
    /**
     * Represents most current last settings of handles of currently selected object.
     * @private
     */
    private currentHandlesSettings?: EnumDictionary<HandlePosition, HandleSettings>;

    constructor(
        private ngZone: NgZone,
        private actions: Actions,
        private store: Store,
        private html: HtmlHelperService,
        private bus: EventBusService
    ) {
        // handle selection
        this.actions
            .pipe(
                takeUntil(this.unsubscribe$),
                ofActionDispatched(EditorActions.SelectObjectCompleteEvent)
            )
            .subscribe((ctx: EditorActions.SelectObjectCompleteEvent) => {
                this.onObjectSelected(ctx.object);
            });

        // handle deselection
        this.actions
            .pipe(
                takeUntil(this.unsubscribe$),
                ofActionDispatched(EditorActions.DeselectObjectsEvent)
            )
            .subscribe(() => {
                this.onObjectsDeselected();
            });

        // handle object data changes
        this.actions
            .pipe(
                takeUntil(this.unsubscribe$),
                ofActionDispatched(EditorActions.ChangeObjectDataEvent)
            )
            .subscribe((ctx: EditorActions.ChangeObjectDataEvent) => {
                if (this.currentObject && this.currentObject.id === ctx.object.id) {
                    this.onObjectDataChanged(ctx.object);
                }
            });

        // handle left button click
        this.actions
            .pipe(
                takeUntil(this.unsubscribe$),
                ofActionDispatched(EditorActions.ObjectHandleLeftClickEvent)
            )
            .subscribe((ctx: EditorActions.ObjectHandleLeftClickEvent) => {
                this.onHandleLeftClick(ctx.position);
            });

        // handle right button click
        this.actions
            .pipe(
                takeUntil(this.unsubscribe$),
                ofActionDispatched(EditorActions.ObjectHandleRightClickEvent)
            )
            .subscribe((ctx: EditorActions.ObjectHandleRightClickEvent) => {
                this.onHandleRightClick(ctx.position);
            });

        this.objectMoveBusSubscription = this.bus.subscribe(
            BUS_EVENT_OBJECT_MOVE,
            (data: UIEditorObject) => {
                this.currentObject = data;
                this.updateHandles(false);
            }
        );
    }

    private onObjectSelected(object: UIEditorObject): void {
        this.currentObject = object;
        this.currentObjectElement = this.html.findObjectContainer(object.id);
        this.currentObjectParentElement = this.html.findParentObject(this.currentObjectElement, this.currentObject.parentId);
        this.updateHandles();
    }

    private onObjectsDeselected(): void {
        this.currentObjectParentElement = undefined;
        this.currentObjectElement = undefined;
        this.currentObject = undefined;
    }

    private onObjectDataChanged(object: UIEditorObject): void {
        this.currentObject = object;
        this.updateHandles();
    }

    public destroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.bus.unsubscribe(this.objectMoveBusSubscription);
    }

    public updateHandles(dispatchChangeEvent: boolean = true): void {

        const object = this.currentObject!;
        const hasParent = !!object.parentId;
        const elementWidth = parseInt(this.currentObjectElement!.style.width);
        const elementHeight = parseInt(this.currentObjectElement!.style.height);
        const parent = {
            x: this.currentObjectParentElement!.offsetLeft,
            y: this.currentObjectParentElement!.offsetTop,
            w: this.currentObjectParentElement!.clientWidth,
            h: this.currentObjectParentElement!.clientHeight,
            rect: this.currentObjectParentElement!.getBoundingClientRect()
        };

        const start = this.calculateDragStartingPoint();
        object.x = start.x;
        object.y = start.y;

        const leftPositioning = new VisualPositioning(
            object.x < 0 ? object.x : 0,
            object.y + elementHeight / 2.0,
            parent.w - object.x,
            undefined,
            undefined,
            -ANCHOR_HANDLE_SIZE / 2.0
        );
        const topPositioning = new VisualPositioning(
            object.x + elementWidth / 2.0,
            object.y < 0 ? object.y : 0,
            undefined,
            parent.h - object.y,
            -ANCHOR_HANDLE_SIZE / 2.0
        );

        let leftPos = object.x + elementWidth;
        if (parent.w - leftPos < MOVEMENT_EDGE_MARGIN) {
            // if right handle not fitting, move it into this container
            leftPos -= MOVEMENT_EDGE_MARGIN - Math.max(parent.w - leftPos, 0);

        }
        const rightPositioning = new VisualPositioning(
            leftPos,
            object.y + elementHeight / 2.0,
            0,
            undefined,
            undefined,
            -ANCHOR_HANDLE_SIZE / 2.0
        );

        let topPos = object.y + elementHeight;
        if (parent.h - topPos < MOVEMENT_EDGE_MARGIN) {
            // if right handle not fitting, move it into this container
            topPos -= MOVEMENT_EDGE_MARGIN - Math.max(parent.h - topPos, 0);
        }
        const bottomPositioning = new VisualPositioning(
            object.x + elementWidth / 2.0,
            topPos,
            undefined,
            0,
            -ANCHOR_HANDLE_SIZE / 2.0
        );

        this.currentHandlesSettings = {
            [HandlePosition.Left]: {
                isActive: true,
                isLocked: false,
                isVisible: true,
                color: this.currentObject!.color,
                positioning: leftPositioning
            },
            [HandlePosition.Top]: {
                isActive: true,
                isLocked: false,
                isVisible: true,
                color: this.currentObject!.color,
                positioning: topPositioning
            },
            [HandlePosition.Right]: {
                isActive: true,
                isLocked: false,
                isVisible: true,
                color: this.currentObject!.color,
                positioning: rightPositioning
            },
            [HandlePosition.Bottom]: {
                isActive: true,
                isLocked: false,
                isVisible: true,
                color: this.currentObject!.color,
                positioning: bottomPositioning
            }
        };

        switch (object.origin) {
            case PlacementOrigin.Default:
            case PlacementOrigin.LeftTop:
                this.currentHandlesSettings[HandlePosition.Left].isActive = true;
                this.currentHandlesSettings[HandlePosition.Left].isLocked = false;

                this.currentHandlesSettings[HandlePosition.Top].isActive = true;
                this.currentHandlesSettings[HandlePosition.Top].isLocked = false;

                this.currentHandlesSettings[HandlePosition.Right].isActive = false;
                this.currentHandlesSettings[HandlePosition.Right].isLocked = hasParent;

                this.currentHandlesSettings[HandlePosition.Bottom].isActive = false;
                this.currentHandlesSettings[HandlePosition.Bottom].isLocked = hasParent;
                break;

            case PlacementOrigin.RightTop:
                this.currentHandlesSettings[HandlePosition.Left].isActive = false;
                this.currentHandlesSettings[HandlePosition.Left].isLocked = false;

                this.currentHandlesSettings[HandlePosition.Top].isActive = true;
                this.currentHandlesSettings[HandlePosition.Top].isLocked = false;

                this.currentHandlesSettings[HandlePosition.Right].isActive = true;
                this.currentHandlesSettings[HandlePosition.Right].isLocked = false;

                this.currentHandlesSettings[HandlePosition.Bottom].isActive = false;
                this.currentHandlesSettings[HandlePosition.Bottom].isLocked = false;
                break;

            case PlacementOrigin.LeftBottom:
                this.currentHandlesSettings[HandlePosition.Left].isActive = true;
                this.currentHandlesSettings[HandlePosition.Left].isLocked = false;

                this.currentHandlesSettings[HandlePosition.Top].isActive = false;
                this.currentHandlesSettings[HandlePosition.Top].isLocked = false;

                this.currentHandlesSettings[HandlePosition.Right].isActive = false;
                this.currentHandlesSettings[HandlePosition.Right].isLocked = false;

                this.currentHandlesSettings[HandlePosition.Bottom].isActive = true;
                this.currentHandlesSettings[HandlePosition.Bottom].isLocked = false;
                break;

            case PlacementOrigin.RightBottom:
                this.currentHandlesSettings[HandlePosition.Left].isActive = false;
                this.currentHandlesSettings[HandlePosition.Left].isLocked = false;

                this.currentHandlesSettings[HandlePosition.Top].isActive = false;
                this.currentHandlesSettings[HandlePosition.Top].isLocked = false;

                this.currentHandlesSettings[HandlePosition.Right].isActive = true;
                this.currentHandlesSettings[HandlePosition.Right].isLocked = false;

                this.currentHandlesSettings[HandlePosition.Bottom].isActive = true;
                this.currentHandlesSettings[HandlePosition.Bottom].isLocked = false;
                break;

            case PlacementOrigin.Center:
                this.currentHandlesSettings[HandlePosition.Left].isActive = false;
                this.currentHandlesSettings[HandlePosition.Left].isLocked = false;

                this.currentHandlesSettings[HandlePosition.Top].isActive = false;
                this.currentHandlesSettings[HandlePosition.Top].isLocked = false;

                this.currentHandlesSettings[HandlePosition.Right].isActive = false;
                this.currentHandlesSettings[HandlePosition.Right].isLocked = false;

                this.currentHandlesSettings[HandlePosition.Bottom].isActive = false;
                this.currentHandlesSettings[HandlePosition.Bottom].isLocked = false;
                break;
        }

        // dispatch store action to inform handles so that they can update
        if (dispatchChangeEvent) {
            this.store.dispatch(new EditorActions.UpdateObjectHandles(this.currentHandlesSettings));
        }
        else {
            // publish direct event bus events to skip store delays and overhead
            this.bus.publish(BUS_EVENT_OBJECT_HANDLE_UPDATE_MAP[HandlePosition.Left], this.currentHandlesSettings[HandlePosition.Left]);
            this.bus.publish(BUS_EVENT_OBJECT_HANDLE_UPDATE_MAP[HandlePosition.Top], this.currentHandlesSettings[HandlePosition.Top]);
            this.bus.publish(BUS_EVENT_OBJECT_HANDLE_UPDATE_MAP[HandlePosition.Right], this.currentHandlesSettings[HandlePosition.Right]);
            this.bus.publish(BUS_EVENT_OBJECT_HANDLE_UPDATE_MAP[HandlePosition.Bottom], this.currentHandlesSettings[HandlePosition.Bottom]);
        }
    }

    private calculateDragStartingPoint(): UiPoint {
        if (this.currentObject!.origin === PlacementOrigin.Center) {
            // calculate current left/top position of object that is centered in parent, e.g. in desktop
            const parentW = this.currentObjectParentElement!.clientWidth;
            const parentH = this.currentObjectParentElement!.clientHeight;
            const objectStyleWidth = parseInt(this.currentObjectElement!.style.width);
            const objectStyleHeight = parseInt(this.currentObjectElement!.style.height);
            const x = parentW / 2.0;
            const y = parentH / 2.0;
            const marginLeft = -objectStyleWidth / 2.0;
            const marginTop = -objectStyleHeight / 2.0;
            return new UiPoint(x + marginLeft, y + marginTop);
        }
        return new UiPoint(this.currentObject!.x, this.currentObject!.y);
    }


    private onHandleLeftClick(position: HandlePosition): void {
        switch (position) {
            case HandlePosition.Left:
            case HandlePosition.Right:
                this.toggleLeftRightHandle(position);
                break;
            case HandlePosition.Top:
            case HandlePosition.Bottom:
                this.toggleTopBottomHandle(position);
                break;
        }
    }

    private onHandleRightClick(position: HandlePosition): void {
        // this.updateHandles(); // for right click just update handles
        // set center
        const object = this.currentObject!;
        if (object.origin == PlacementOrigin.Center) {
            object.origin = PlacementOrigin.LeftTop;
        }
        else {
            object.origin = PlacementOrigin.Center;
        }
        this.store.dispatch(new EditorActions.ChangeObjectData(this.currentObject!));
    }

    private toggleLeftRightHandle(position: HandlePosition): void {

        const object = this.currentObject!;
        const hasParent = !!object.parentId;
        const lastOrigin = object.origin;
        const settingsLeft = this.currentHandlesSettings![HandlePosition.Left];
        const settingsRight = this.currentHandlesSettings![HandlePosition.Right];
        const settingsTop = this.currentHandlesSettings![HandlePosition.Top];
        const settingsBottom = this.currentHandlesSettings![HandlePosition.Bottom];

        if (object.origin === PlacementOrigin.Center && position === HandlePosition.Left) {
            if (!settingsTop.isLocked) {
                object.origin = PlacementOrigin.LeftTop;
            }
            else if (!settingsBottom.isLocked) {
                object.origin = PlacementOrigin.LeftBottom;
            }
            else {
                return; // no reaction, top and bottom handles are locked
            }
        }
        else if (object.origin === PlacementOrigin.Center && position === HandlePosition.Right) {
            if (hasParent) {
                // when we are in center (within parent) placement and want to click right handle, it cannot be anchored to right
                object.origin = PlacementOrigin.LeftTop;
            }
            else if (!settingsTop.isLocked) {
                object.origin = PlacementOrigin.RightTop;
            }
            else if (!settingsBottom.isLocked) {
                object.origin = PlacementOrigin.RightBottom;
            }
            else {
                return; // no reaction, top and bottom handles are locked
            }
        }
        else if (object.origin === PlacementOrigin.LeftTop) {
            object.origin = settingsRight.isLocked ? object.origin : PlacementOrigin.RightTop;
        }
        else if (object.origin === PlacementOrigin.LeftBottom) {
            object.origin = settingsRight.isLocked ? object.origin : PlacementOrigin.RightBottom;
        }
        else if (object.origin === PlacementOrigin.RightTop) {
            object.origin = settingsLeft.isLocked ? object.origin : PlacementOrigin.LeftTop;
        }
        else if (object.origin === PlacementOrigin.RightBottom) {
            object.origin = settingsLeft.isLocked ? object.origin : PlacementOrigin.LeftBottom;
        }
        else {
            return;
        }

        if (lastOrigin != object.origin) {
            this.store.dispatch(new EditorActions.ChangeObjectData(this.currentObject!));
        }
    }

    private toggleTopBottomHandle(position: HandlePosition): void {

        const object = this.currentObject!;
        const hasParent = !!object.parentId;
        const lastOrigin = object.origin;
        const settingsLeft = this.currentHandlesSettings![HandlePosition.Left];
        const settingsRight = this.currentHandlesSettings![HandlePosition.Right];
        const settingsTop = this.currentHandlesSettings![HandlePosition.Top];
        const settingsBottom = this.currentHandlesSettings![HandlePosition.Bottom];

        if (object.origin === PlacementOrigin.Center && position === HandlePosition.Top) {
            if (!settingsLeft.isLocked) {
                object.origin = PlacementOrigin.LeftTop;
            }
            else if (!settingsRight.isLocked) {
                object.origin = PlacementOrigin.RightTop;
            }
            else {
                return; // no reaction, left and right handles are locked
            }
        }
        else if (object.origin === PlacementOrigin.Center && position === HandlePosition.Bottom) {
            if (hasParent) {
                // when we are in center (within parent) placement and want to click bottom handle, it cannot be anchored to bottom
                object.origin = PlacementOrigin.LeftTop;
            }
            else if (!settingsLeft.isLocked) {
                object.origin = PlacementOrigin.LeftBottom;
            }
            else if (!settingsRight.isLocked) {
                object.origin = PlacementOrigin.RightBottom;
            }
            else {
                return; // no reaction, top and bottom handles are locked
            }
        }
        else if (object.origin === PlacementOrigin.LeftTop) {
            object.origin = settingsBottom.isLocked ? object.origin : PlacementOrigin.LeftBottom;
        }
        else if (object.origin === PlacementOrigin.RightTop) {
            object.origin = settingsBottom.isLocked ? object.origin : PlacementOrigin.RightBottom;
        }
        else if (object.origin === PlacementOrigin.LeftBottom) {
            object.origin = settingsTop.isLocked ? object.origin : PlacementOrigin.LeftTop;
        }
        else if (object.origin === PlacementOrigin.RightBottom) {
            object.origin = settingsTop.isLocked ? object.origin : PlacementOrigin.RightTop;
        }
        else {
            return;
        }

        if (lastOrigin != object.origin) {
            this.store.dispatch(new EditorActions.ChangeObjectData(this.currentObject!));
        }
    }
}
