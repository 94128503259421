import {Injectable} from '@angular/core';
import {Selector, State} from '@ngxs/store';
import * as _ from 'lodash';
import {UIEditorObject} from '../models/ui-editor-object.model';
import {UITrainingStep} from '../models/ui-training-step.model';
import {EditorFlow} from './models/editor-flow.enum';
import {EditorMode} from './models/editor-mode.enum';
import {defaultEditorState, EditorStateModel} from './models/editor-state.model';
import {HintBoxSettingsModel} from './models/hint-box-settings.model';

@State<EditorStateModel>({name: 'editor', defaults: defaultEditorState})
@Injectable()
export class EditorSelectors {

    @Selector()
    static getEditorMode(state: EditorStateModel): EditorMode {
        return state.mode;
    }

    @Selector()
    static getEditorFlow(state: EditorStateModel): EditorFlow {
        return state.flowSettings.flow;
    }

    @Selector()
    static getHintBoxSettings(state: EditorStateModel): HintBoxSettingsModel {
        return state.hintBoxSettings;
    }

    @Selector()
    static getSteps(state: EditorStateModel): UITrainingStep[] {
        return state.training.steps;
    }

    @Selector()
    static getSelectedStep(state: EditorStateModel): UITrainingStep | undefined {
        if (_.isNil(state.selectedStepIndex)) {
            return undefined;
        }
        return state.training.steps[state.selectedStepIndex];
    }

    @Selector()
    static getObjects(state: EditorStateModel): UIEditorObject[] {
        const step = EditorSelectors.getSelectedStep(state);
        return step ? step.objects || [] : [];
    }

    @Selector()
    static getSelectedStepIndex(state: EditorStateModel): number {
        return state.selectedStepIndex;
    }

    @Selector()
    static getIsStepSelected(state: EditorStateModel): boolean {
        return state.selectedStepIndex !== undefined;
    }

    @Selector()
    static isAnyObjectSelected(state: EditorStateModel): boolean {
        return !!state.selectedEditorObjectId;
    }

    @Selector()
    static getSelectedObjectId(state: EditorStateModel): string | undefined {
        return state.selectedEditorObjectId;
    }

    @Selector()
    static getSelectedObject(state: EditorStateModel): UIEditorObject | undefined {
        if (state.selectedEditorObjectId) {
            const objectIndex = state.training.steps[state.selectedStepIndex].objectsById[state.selectedEditorObjectId];
            return state.training.steps[state.selectedStepIndex].objects[objectIndex];
        }
        return undefined;
    }
}
