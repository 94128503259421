import {ObjectColor} from './object-color.enum';
import {PlacementOrigin} from './placement-origin.enum';

export interface EditorObjectModel {
    /**
     * Unique object identifier in format of UUID / GUID string.
     */
    id: string;
    /**
     * An optional custom name for the object.
     */
    name?: string;
    /**
     * A parent object ID - if empty or null, parent object is not defined, so we use desktop as parent.
     */
    parentId?: string;
    /**
     * UI only related color for Editor purposes.
     */
    color?: ObjectColor;
    /**
     * Position x of the left top corner of the interactive box
     * (e.g. 20, 30 means 20% and 30% for no parent defined, or 20px, 30px for defined parent object).
     */
    x: number;
    /**
     * Position y of the left top corner of the interactive box
     * (e.g. 20, 30 means 20% and 30% for no parent defined, or 20px, 30px for defined parent object).
     */
    y: number;
    /**
     * Width of the interactive box in pixels.
     */
    width: number;
    /**
     * Height of the interactive box in pixels.
     */
    height: number;
    /**
     * An origin point and respective anchor point, used to place the object using x,y position.
     * The origin of object is always relative to its parent. If the parent is missing, it means
     * that there is no parent and we place objects relative to desktop using % units.
     * If the parent is defined, we use px units instead and they are calculated relative to the parent.
     *
     * Below examples for missing parent - relative to desktop.
     * --------------------------------------------------------
     *
     * @example Parent object NOT defined (we use desktop with % units as coordinates)
     * When origin = {@link PlacementOrigin.LeftTop} - the default one, the x and y coordinates are calculated from
     * left upper screen corner.
     *  x = 30, y = 20 (30% from left bound of screen, 20% from top bound of screen)
     *
     *     ===============================
     *     ||            ˄              ||
     *     ||            |  y           ||
     *     ||            |              ||
     *     ||    x   ++++++++++         || SCREEN
     *     ||  <---- + OBJECT +         || BOUNDS
     *     ||        ++++++++++         ||
     *     ||                           ||
     *     ||                           ||
     *     ===============================
     *
     * @example Parent object NOT defined (we use desktop with % units as coordinates)
     * When origin = {@link PlacementOrigin.Center}, the x and y coordinates are ignored, because the element is
     * always centered relative to desktop (except if it has defined parent).
     *
     * NOTE: In the future we plan to use x,y fields as additional margins even for centered objects.
     *
     *     ===============================
     *     ||                           ||
     *     ||                           ||
     *     ||        ++++++++++         || SCREEN
     *     ||        + OBJECT +         || BOUNDS
     *     ||        ++++++++++         ||
     *     ||                           ||
     *     ||                           ||
     *     ===============================
     *
     * @example Parent object NOT defined (we use desktop with % units as coordinates)
     * When origin = {@link PlacementOrigin.RightTop}, the x and y coordinates are calculated from
     * right upper screen corner.
     *  x = 30, y = 20 (30% from right bound of screen, 20% from top bound of screen)
     *
     *     ===============================
     *     ||            ˄              ||
     *     ||            |  y           ||
     *     ||            |              ||
     *     ||        ++++++++++   x     || SCREEN
     *     ||        + OBJECT + ----->  || BOUNDS
     *     ||        ++++++++++         ||
     *     ||                           ||
     *     ||                           ||
     *     ===============================
     *
     *
     * @example Parent object is defined (px units as coordinates)
     * When origin = {@link PlacementOrigin.RightTop}, the x and y coordinates are calculated from
     * right upper parent corner.
     *  x = 300, y = 200 (300px from right bound of parent box, 200px from top bound of parent box)
     *
     *     ====================================================================
     *     ||                                                                ||
     *     ||                                                                ||
     *     ||                                                                ||
     *     ||                parent box object                               ||
     *     ||      -------------------------------------                     ||
     *     ||      |                ˄                  |                     ||
     *     ||      |                |  y               |                     ||
     *     ||      |                |                  |                     ||
     *     ||      |            ++++++++++      x      |                     || SCREEN
     *     ||      |            + OBJECT + ----------> |                     || BOUNDS
     *     ||      |            ++++++++++             |                     ||
     *     ||      |                                   |                     ||
     *     ||      |                                   |                     ||
     *     ||      |                                   |                     ||
     *     ||      |                                   |                     ||
     *     ||      -------------------------------------                     ||
     *     ||                                                                ||
     *     ||                                                                ||
     *     ||                                                                ||
     *     ||                                                                ||
     *     ||                                                                ||
     *     ====================================================================
     */
    origin: PlacementOrigin;
}
