<div #imageObject
     class="image-object color-{{color.toLowerCase()}}"
     (click)="onClick($event)"
     [style.background-image]="image"
     [class.is-hidden]="objectModel.visible === false"
     [class.is-selected]="isSelected"
     [class.is-disabled]="!isEnabled"
     [style.z-index]="isSelected ? '99' : 'auto'"
     [attr.editor-object-id]="objectModel.id">
    <div class="dashed-frame"></div>
    <ip-image-object *ngFor="let child of (children | async)" [objectModel]="child"></ip-image-object>
    <ip-object-settings-icon-handle *ngIf="isSelected"></ip-object-settings-icon-handle>
</div>

<!-- Handles for component -->
<ip-anchor-handle *ngIf="isSelected" (initialized)="onHandleInitialized()"
                  [position]="HandlePosition.Left"></ip-anchor-handle>
<ip-anchor-handle *ngIf="isSelected" (initialized)="onHandleInitialized()"
                  [position]="HandlePosition.Right"></ip-anchor-handle>
<ip-anchor-handle *ngIf="isSelected" (initialized)="onHandleInitialized()"
                  [position]="HandlePosition.Top"></ip-anchor-handle>
<ip-anchor-handle *ngIf="isSelected" (initialized)="onHandleInitialized()"
                  [position]="HandlePosition.Bottom"></ip-anchor-handle>
