import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppEnvironment, CommonLibConfig, CommonLibModule} from 'common-lib';
import {environment} from '../../../dashboard/src/environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {EditorModule} from './editor/editor.module';
import {LoginPageComponent} from './login-page/login-page.component';
import {NgbModalModule, NgbToastModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [AppComponent, LoginPageComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        EditorModule,
        CommonLibModule.forRoot(
            new CommonLibConfig({
                apiUrl: environment.apiUrl,
                filesUrl: environment.filesUrl,
                environment: environment.production ? AppEnvironment.Production : AppEnvironment.Test
            })
        ),
        NgbModalModule,
        NgbToastModule
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
