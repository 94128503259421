import {OperatingSystem} from '../operating-system.enum';
import {PricingModel} from './pricing.model';

export interface TrainingSimpleModel {
    id: string;
    name: string;
    description: string;
    os: OperatingSystem;
    osVersion: string;
    difficultLevel: number;
    isArchived: boolean;
    /**
     * ISO String date format.
     */
    createdDate: string;
    /**
     * ISO String date format.
     */
    lastUpdateDate?: string;
    /**
     * ISO String date format.
     */
    archivedDate?: string;
    pricing: PricingModel;
}
