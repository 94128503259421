import {FileSimpleModel} from './file.simple-model';

export interface FileModel extends FileSimpleModel {
    /**
     * A content string representing a base64 encoded file contents.
     */
    content: string;
}




