import {EditorObjectModel} from './editor-object.model';
import {ObjectActionModel} from './object-action.model';
import {TooltipModel} from './tooltip.model';

/**
 * A step object that can be hovered or clicked/tapped by the user.
 */
export interface InteractiveObjectModel extends EditorObjectModel {
    /**
     * Optional tooltip that is displayed when the user hovers the interactive field.
     */
    tooltip?: TooltipModel;
    /**
     * Defines a list of actions that will be executed one by one for click event on object.
     */
    clickActions?: ObjectActionModel[];
    /**
     * Defines a list of actions that will be executed one by one when mouse entered object area.
     */
    mouseInActions?: ObjectActionModel[];
    /**
     * Defines a list of actions that will be executed one by one when mouse left object area.
     */
    mouseOutActions?: ObjectActionModel[];
    /**
     * Indicates whether the box is visible when training course is played.
     * If set as true, the box will appear as light semitransparent box visible on the screen,
     * otherwise it is invisible.
     */
    visible: boolean;
}
