import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ObjectColor} from 'common-lib';
import {environment} from '../../../../../environments/environment';
import {DEFAULT_OBJECT_COLOR} from '../../../constants';
import {UIEditorObject} from '../../../models/ui-editor-object.model';

@Component({
    selector: 'ip-image-object-settings-modal',
    templateUrl: './image-object-settings.modal.html',
    styleUrls: ['./image-object-settings.modal.scss']
})
export class ImageObjectSettingsModal implements OnInit, OnDestroy {
    @Input()
    public editorObject!: UIEditorObject;

    public get imageUrl(): string {
        return `${environment.filesUrl}/${this.editorObject?.imageId}`;
    }

    public get imageType(): string {
        return `${this.editorObject?.imageType}`;
    }

    public get color(): ObjectColor {
        return this.editorObject ? this.editorObject.color || DEFAULT_OBJECT_COLOR : DEFAULT_OBJECT_COLOR;
    }

    constructor(public activeModal: NgbActiveModal) {

    }

    public ngOnInit(): void {
    }

    public ngOnDestroy(): void {

    }

    public onObjectColorChange(color: ObjectColor): void {
        this.editorObject.color = color;
    }

    public onObjectNameChange(name: string): void {
        this.editorObject.name = name;
    }

    public onParentObjectChanged(parentId: string): void {
        this.editorObject.parentId = parentId;
    }

    public onChangePicture(): void {
        console.log(`Change picture of ${this.imageUrl}`);
    }
}

