import {Component, EventEmitter, HostBinding, HostListener, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

export enum MenuPosition {
    left = 'left',
    right = 'right',
}

@Component({
    selector: 'ip-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnChanges {
    @HostBinding('class.show') showClass: boolean = false;
    @HostBinding('class.right') rightClass: boolean = false;

    @HostListener('click') onClick() {
        this.hide();
    }

    @Input() show = false;
    @Input() position: MenuPosition = MenuPosition.left;
    @Output() hideMenu = new EventEmitter<boolean>();

    ngOnChanges(changes: SimpleChanges) {
        if (changes.show) {
            this.showClass = changes.show.currentValue;
        }
        if (changes.position?.currentValue === MenuPosition.right) {
            this.rightClass = true;
        }
    }

    onOverlayClick(): void {
        this.hide();
    }

    private hide(): void {
        this.hideMenu.emit(true);
    }
}
