import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {fromEvent, Observable, Subject} from 'rxjs';
import {ThrottleConfig} from 'rxjs/internal-compatibility';
import {mergeMap, takeUntil, takeWhile, throttleTime} from 'rxjs/operators';
import {TrainingStepModel} from 'common-lib';
import {HtmlHelperService} from '../../services/html-helper.service';
import {EditorActions} from '../../store/editor.actions';
import {EditorSelectors} from '../../store/editor.selectors';

@Component({
    selector: 'ip-windows-desktop',
    templateUrl: './windows-desktop.component.html',
    styleUrls: ['./windows-desktop.component.scss']
})
export class WindowsDesktopComponent implements OnInit, AfterViewInit, OnDestroy {
    private windowResize$: Observable<Event>;
    private unsubscribe$ = new Subject<void>();
    private isAnyObjectSelected: boolean = false;

    @ViewChild('workspace', {static: true}) public workspaceRef!: ElementRef;
    @Input() public disabledToolbar: boolean = false;
    @Select(EditorSelectors.getSelectedStep) public selectedStep$!: Observable<TrainingStepModel>;
    @Select(EditorSelectors.isAnyObjectSelected) public isAnyObjectSelected$!: Observable<boolean>;

    constructor(private readonly store: Store, private readonly html: HtmlHelperService) {
        this.windowResize$ = fromEvent(window, 'resize');
        this.windowResize$
            .pipe(
                takeUntil(this.unsubscribe$),
                takeWhile(() => this.isAnyObjectSelected),
                throttleTime(100,
                    undefined,
                    <ThrottleConfig> {leading: true, trailing: true}
                )
            )
            .subscribe(() => this.store.dispatch(new EditorActions.DeselectObjects())); // deselect all objects on window resize

        this.isAnyObjectSelected$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((isSelected) => this.isAnyObjectSelected = isSelected);
    }

    public onWorkspaceClick(): void {
        this.store.dispatch(new EditorActions.DesktopClick());
    }

    public ngOnInit(): void {

    }

    public ngAfterViewInit(): void {
        this.html.registerDesktopWorkspace(this.workspaceRef.nativeElement);
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
