import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ObjectColor, PopupService} from 'common-lib';
import {DEFAULT_OBJECT_COLOR, OBJECT_COLORS_DATA, OBJECT_COLORS_MAP} from '../../../constants';
import * as uuid from 'uuid';

@Component({
    selector: 'ip-object-color-setting',
    templateUrl: './object-color-setting.component.html',
    styleUrls: ['./object-color-setting.component.scss']
})
export class ObjectColorSettingComponent implements OnInit {

    @Input()
    public selectedColor: ObjectColor = DEFAULT_OBJECT_COLOR;

    @Output()
    public selectedColorChange: EventEmitter<ObjectColor> = new EventEmitter<ObjectColor>();

    /**
     * A label displayed on the left side of the color.
     */
    @Input()
    public label: string | undefined;

    public readonly colors = OBJECT_COLORS_DATA;
    public readonly popupId: string = uuid.v4();

    public get selectedCssColorName(): string {
        return OBJECT_COLORS_MAP[this.selectedColor || DEFAULT_OBJECT_COLOR];
    }

    constructor(private popupService: PopupService) {

    }

    public ngOnInit(): void {
    }

    public toggleMenu(): void {
        this.popupService.show(this.popupId);
    }

    public onMenuItemClick(color: ObjectColor): void {
        this.selectedColor = color;
        this.selectedColorChange.emit(this.selectedColor || DEFAULT_OBJECT_COLOR);
        this.popupService.hide(this.popupId);
    }

}
