import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {EditorObjectModel, PlacementOrigin} from 'common-lib';
import * as _ from 'lodash';
import {OBJECT_TAGS_MAP, SELECTED_OBJECT_BORDER_WIDTH} from '../constants';
import {UiEditorObjectType} from '../models/ui-editor-object-type.enum';
import {UIEditorObject} from '../models/ui-editor-object.model';
import {VisualPositioning} from '../models/visual-positioning';

@Injectable({
    providedIn: 'root'
})
export class HtmlHelperService {
    private readonly renderer: Renderer2;
    private desktopElement!: HTMLElement;

    constructor(rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    /**
     * Registers a desktop host HTML element, that contains all the editor objects.
     * Object is required to successfully position and manipulate of editor objects.
     * @param desktopElement The element representing native HTML object.
     */
    public registerDesktopWorkspace(desktopElement: HTMLElement): void {
        this.desktopElement = desktopElement;
    }

    public getDesktopBoundingRect(): DOMRect {
        return this.desktopElement.getBoundingClientRect();
    }

    /**
     * Updates placement of given HTML element representing root of
     * @param objectModel A model to use for initialization.
     */
    public updateVisualObject(objectModel: UIEditorObject): void {
        if (!this.desktopElement) {
            throw new Error('HTML Helper Service requires desktop workspace HTML element to be registered.');
        }

        const element = this.findObjectContainer(objectModel.id);
        if (!element) {
            throw new Error(`The editor object component "${objectModel.id}" doesn't contain required HTML element` +
                ` with attribute [editor-object-id="${objectModel.id}"].`);
        }

        // adjust size
        this.renderer.setStyle(
            element,
            'width',
            (objectModel.width + SELECTED_OBJECT_BORDER_WIDTH * 2) + 'px'
        );
        this.renderer.setStyle(
            element,
            'height',
            (objectModel.height + SELECTED_OBJECT_BORDER_WIDTH * 2) + 'px'
        );

        this.positionEditorObject(objectModel, element);
    }

    public updateVisualObjectPositioning(position: VisualPositioning, element: HTMLElement): void {
        this.renderer.setStyle(element, 'left', _.isNil(position.left) ? 'unset' : position.left + position.posUnit);
        this.renderer.setStyle(element, 'top', _.isNil(position.top) ? 'unset' : position.top + position.posUnit);
        this.renderer.setStyle(element, 'right', _.isNil(position.right) ? 'unset' : position.right + position.posUnit);
        this.renderer.setStyle(element, 'bottom', _.isNil(position.bottom) ? 'unset' : position.bottom + position.posUnit);
        this.renderer.setStyle(element, 'margin-left', _.isNil(position.marginLeft) ? 'unset' : position.marginLeft + 'px');
        this.renderer.setStyle(element, 'margin-top', _.isNil(position.marginTop) ? 'unset' : position.marginTop + 'px');
        this.renderer.setStyle(element, 'margin-right', _.isNil(position.marginRight) ? 'unset' : position.marginRight + 'px');
        this.renderer.setStyle(element, 'margin-bottom', _.isNil(position.marginBottom) ? 'unset' : position.marginBottom + 'px');
    }

    /**
     * Finds the x,y coordinates of {@param currentObjectId} relatively to {@param relativeParentId},
     * assuming that current object will be positioned as origin = {@see PlacementOrigin.LeftTop}.
     * @param currentObjectId Current object which position is searched for.
     * @param relativeParentId A parent object for given current object.
     */
    public getRelativePositionTo(currentObjectId: string, relativeParentId: string | undefined): { x: number, y: number } {
        const result = {x: 0, y: 0};
        let parent: HTMLElement;
        let current: HTMLElement;

        if (!currentObjectId) {
            throw new Error('A parameter currentObjectId must be defined to find relative x,y coordinates.');
        }

        if (!relativeParentId) {
            parent = this.desktopElement;
        }
        else {
            parent = this.findObjectContainer(relativeParentId);
        }

        current = this.findObjectContainer(currentObjectId);
        const parentRect = parent.getBoundingClientRect();
        const currentRect = current.getBoundingClientRect();

        result.x = currentRect.x - parentRect.x;
        result.y = currentRect.y - parentRect.y;

        return result;
    }

    /**
     * Searches for an editor object container (not host wrapper but real inner container)
     * with special attribute editor-object-id.
     * @param id Id of the object to find.
     * @param viewContainerElement Optional current component host element, to search withing.
     * If this value is empty, the component is searched within whole document.
     */
    public findObjectContainer(id: string, viewContainerElement?: HTMLElement): HTMLElement {
        if (!viewContainerElement) {
            return document.querySelector(`[editor-object-id="${id}"]`) as HTMLElement;
        }
        return viewContainerElement.querySelector(`[editor-object-id="${id}"]`) as HTMLElement;
    }

    /**
     * Searches for a editor object component container (host wrapper).
     * @param id Object ID which uIO component should be found.
     * @param type The target component type, important to find correct TAG name.
     */
    public findComponentElement(id: string, type: UiEditorObjectType): HTMLElement {
        const innerElement = this.findObjectContainer(id);
        const tagName = OBJECT_TAGS_MAP[type];
        let element = innerElement;
        while (element.tagName.toLowerCase() != tagName) {
            element = element.parentElement as HTMLElement;
        }
        return element;
    }

    /**
     * Searches for a parent editor object of {@param currentElement} that has special attribute
     * editor-object-id="{@param parentId}". Parent must be defined.
     * @param parentId Id of parent object to search for, if empty the parent that will be returned is a desktop.
     * @param currentElement
     */
    public findParentObject(currentElement: HTMLElement, parentId?: string): HTMLElement {
        if (!parentId) {
            return this.desktopElement;
        }
        let element = currentElement;
        do {
            element = element.parentElement as HTMLElement;
        }
        while (element.getAttribute('editor-object-id') != parentId);
        return element;
    }

    public attachClick(element: HTMLElement | Document | Window, callback: (event: MouseEvent) => boolean | void): () => void {
        return this.attachEventHandler(element, 'click', callback);
    }

    public attachMouseMove(element: HTMLElement | Document | Window, callback: (event: MouseEvent) => boolean | void): () => void {
        return this.attachEventHandler(element, 'mousemove', callback);
    }

    public attachMouseDown(element: HTMLElement | Document | Window, callback: (event: MouseEvent) => boolean | void): () => void {
        return this.attachEventHandler(element, 'mousedown', callback);
    }

    public attachMouseUp(element: HTMLElement | Document | Window, callback: (event: MouseEvent) => boolean | void): () => void {
        return this.attachEventHandler(element, 'mouseup', callback);
    }

    public attachArrowUpPress(element: HTMLElement | Document | Window, callback: (event: KeyboardEvent) => boolean | void): () => void {
        return this.attachEventHandler(element, 'keydown.arrowup', callback);
    }

    public attachArrowDownPress(element: HTMLElement | Document | Window, callback: (event: KeyboardEvent) => boolean | void): () => void {
        return this.attachEventHandler(element, 'keydown.arrowdown', callback);
    }

    public attachArrowLeftPress(element: HTMLElement | Document | Window, callback: (event: KeyboardEvent) => boolean | void): () => void {
        return this.attachEventHandler(element, 'keydown.arrowleft', callback);
    }

    public attachArrowRightPress(element: HTMLElement | Document | Window, callback: (event: KeyboardEvent) => boolean | void): () => void {
        return this.attachEventHandler(element, 'keydown.arrowright', callback);
    }

    public hideObject(selector: string): void {
        const objectToHide = document.querySelector(selector) as HTMLElement;
        if (objectToHide) {
            this.renderer.addClass(objectToHide, 'invisible');
        }
    }

    public revealObject(selector: string): void {
        const objectToReveal = document.querySelector(selector) as HTMLElement;
        if (objectToReveal) {
            this.renderer.removeClass(objectToReveal, 'invisible');
        }
    }

    private attachEventHandler(element: HTMLElement | Document | Window, eventName: string, callback: (event: any) => boolean | void): () => void {
        if (element instanceof Document) {
            return this.renderer.listen('document', eventName, callback);
        }
        else if (element instanceof Window) {
            return this.renderer.listen('window', eventName, callback);
        }
        return this.renderer.listen(element, eventName, callback);
    }

    /**
     * Sets position for left top corner of given HTML element.
     * Correct placement and origin is calculated automatically.
     * @param objectModel The data representation of the editor object.
     * @param element The HTML representation of the editor object.
     * @private
     */
    private positionEditorObject(objectModel: EditorObjectModel, element: HTMLElement): void {

        let x = objectModel.x;
        let y = objectModel.y;
        const hasParent = !!objectModel.parentId;
        const parent = this.findParentObject(element, objectModel.parentId);
        const objectStyleWidth = parseInt(element.style.width);
        const objectStyleHeight = parseInt(element.style.height);
        const parentW = parent.clientWidth;
        const parentH = parent.clientHeight;

        // set correct position based on object origin and parent
        // for Desktop as a parent use % units for setting the position
        let left: number | undefined;
        let top: number | undefined;
        let right: number | undefined;
        let bottom: number | undefined;
        let unit: 'px' | '%';
        let marginLeft: number | undefined;
        let marginTop: number | undefined;
        let calcX: (val: number) => number;
        let calcY: (val: number) => number;

        if (!hasParent || objectModel.origin === PlacementOrigin.Center) {
            unit = '%';
            calcX = (val: number) => val / parentW * 100.0;
            calcY = (val: number) => val / parentH * 100.0;
        }
        else {
            calcX = (val: number) => val;
            calcY = (val: number) => val;
            unit = 'px';
        }

        switch (objectModel.origin) {
            case PlacementOrigin.Default:
            case PlacementOrigin.LeftTop:
                left = calcX(x);
                top = calcY(y);
                break;

            case PlacementOrigin.RightTop:
                x = parentW - x - objectStyleWidth;
                right = calcX(x);
                top = calcY(y);
                break;

            case PlacementOrigin.LeftBottom:
                y = parentH - y - objectStyleHeight;
                left = calcX(x);
                bottom = calcY(y);
                break;

            case PlacementOrigin.RightBottom:
                x = parentW - x - objectStyleWidth;
                y = parentH - y - objectStyleHeight;
                right = calcX(x);
                bottom = calcY(y);
                break;

            case PlacementOrigin.Center:
                x = parentW / 2.0;
                y = parentH / 2.0;
                marginLeft = -objectStyleWidth / 2.0;
                marginTop = -objectStyleHeight / 2.0;
                left = calcX(x);
                top = calcY(y);
                break;
        }

        const vp = new VisualPositioning(
            left, top, right, bottom, marginLeft, marginTop, undefined, undefined, unit
        );
        this.updateVisualObjectPositioning(vp, element);
    }
}
