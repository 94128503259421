import {WindowsDateTime} from '../models/date-time.model';

export class UtilsService {
    public static getWindowsDateTime(): WindowsDateTime {
        const dateTime: WindowsDateTime = {time: '', date: ''};
        const currentDate = new Date();
        const hours = currentDate.getHours(),
            minutes = currentDate.getMinutes(),
            date = currentDate.getDate(),
            month = currentDate.getMonth(),
            year = currentDate.getFullYear();

        dateTime.time = `${formatNumber(hours)}:${formatNumber(minutes)}`;
        dateTime.date = `${formatNumber(date)}.${formatNumber(month + 1)}.${year}`;
        return dateTime;
    }
}

function formatNumber(value: number, minimumIntegerDigits = 2): string {
    return value.toLocaleString('en-US', {
        minimumIntegerDigits,
        useGrouping: false
    });
}
