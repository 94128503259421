import {TrainingTestResultsModel} from './training-test-results.model';

export interface UserTrainingInfoModel {
    /**
     * Related training id.
     */
    trainingId: string;
    /**
     * Where did the user stop training on last visit. If empty, training was not opened yet.
     */
    currentStepId?: string;
    /**
     * Did user complete the training course? - not related to tests, only theoretical part.
     */
    isTrainingCompleted: boolean;
    /**
     * What is the current progress of course (overall value).
     * @example
     * 0.3 means 30%
     * 0.567 mean 57%
     * ... etc.
     */
    progress: number;
    /**
     * If user checked his skills with test, here will be results.
     */
    testResults?: TrainingTestResultsModel;
    /**
     * When user was opening this training for the last time. May be empty if training was not opened yet.
     * ISO String date format.
     */
    lastOpenDate?: string;
    /**
     * When user completed this training, no date if training is not completed yet.
     * ISO String date format.
     */
    completedDate?: string;
    /**
     * How user rated this training. If empty, nobody rated this training yet.
     */
    userRating?: number;
    /**
     * Additional comment on this training course.
     */
    userComment?: string;
}
